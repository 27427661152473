import { useState } from "react";
import Logout from "../Logout";
import Header from "../contents/header";
import useCurrentUser from "../currentUser/currentuser";
import ProjectList from "../projectList/projectList";
import LeaderRegister from "../leaderregister";
import NotificationList from "../notifications/notificationList";
import { MDBContainer } from "mdb-react-ui-kit";
import AvatarD from "../contents/avatar";
import ShapeSection from "./shapeSection";
import ProjectsWorkList from "../work/projectslworklist";

function ProgrammerDashboard() {
  const userData = useCurrentUser();
  const [activePage, setActivePage] = useState("Home");

  const renderContent = () => {
    switch (activePage) {
      case "Home":
        return (
          <ShapeSection
            title={"Dashboard"}
            compenent={<ProjectList />}
            userData={userData}
          />
        );
      case "Work":
        return (
          <ShapeSection
            title={"news"}
            compenent={<ProjectsWorkList />}
            userData={userData}
          />
        );
      case "Request":
        return (
          <ShapeSection
            title={"Request"}
            compenent={<LeaderRegister />}
            userData={userData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="all-things-here">
      <Header
        children={activePage}
        user={userData}
        onItemSelected={setActivePage}
      />
      {renderContent()}
      <NotificationList />
    </div>
  );
}

export default ProgrammerDashboard;
