import {
  MDBContainer,
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";
import { ProjectEmploymentDetails } from "./projectemploymentdetails";
import useCurrentUser from "../currentUser/currentuser";
import { ApprovedProject } from "../notifications/notification";
import { useState, useEffect } from "react";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db, getUserById } from "../firebase"; // Ensure you import the `db` object from your Firebase configuration

function ProjectsEmploymentList() {
  const Cuser = useCurrentUser();
  const [currentPage, setCurrentPage] = useState(1); // for tracking the current page
  const [programmersPerPage] = useState(12); // number of programmers per page
  const [pageWindowSize] = useState(5); // Number of visible page links
  const [programmers, setProgrammers] = useState([]); // to store programmers list fetched from Firestore

  useEffect(() => {
    // Use snapshot listener to fetch data in real-time
    const fetchProgrammers = () => {
      const q = query(collection(db, "requestworkingprojects"));
      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const programmersData = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const data = doc.data();
            const userProg = await getUserById(data.programmerId); // Wait for the user data to be fetched

            if (userProg) {
              return {
                fullname: `${userProg.firstName} ${userProg.lastName}`,
                request: data.type,
                workId: data.workId,
                programmerId: data.programmerId,
                time: data.time,
                type: data.type,
                approved: data?.approved,
              };
            }
            return null; // Handle case if user data is not found
          })
        );

        // Filter out null entries in case there were any issues fetching user data
        setProgrammers(
          programmersData.filter((programmer) => programmer !== null)
        );
      });

      return () => unsubscribe(); // Cleanup the listener on component unmount
    };

    fetchProgrammers();
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  if (Cuser.role === "groupleader" && Cuser.working === false) {
    return (
      <span style={{ textAlign: "center", color: "var(--primary-color-h1)" }}>
        not yet
      </span>
    );
  }

  const indexOfLastProject = currentPage * programmersPerPage;
  const indexOfFirstProject = indexOfLastProject - programmersPerPage;
  const currentprogrammers = programmers.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  const totalPages = Math.ceil(programmers.length / programmersPerPage);
  // Calculate the range of pages to display in pagination
  const startPage = Math.max(1, currentPage - Math.floor(pageWindowSize / 2));
  const endPage = Math.min(totalPages, startPage + pageWindowSize - 1);
  const pageNumbers = [];
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <MDBContainer className="projectlistusers-container">
      {Cuser.role === "groupleader" && Cuser.working === true && (
        <div style={{ height: "35%" }}>
          <ApprovedProject projectId={Cuser.projectWorkingFor} />
        </div>
      )}
      <div style={{height: "65%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
        <div className="row">
          {currentprogrammers.length !== 0 &&
            currentprogrammers.map((programmer) => (
              (programmer.approved !== true) && <ProjectEmploymentDetails
                key={programmer.programmerId}
                programmer={programmer}
              />
            ))}
        </div>
        <nav aria-label="..." className="projectlistusers-pagination-nav">
          <MDBPagination size="md" className="mb-0 projectlistusers-pagination">
            <MDBPaginationItem
              disabled={currentPage <= 1}
              className="projectlistusers-previous-item"
            >
              <MDBPaginationLink
                href="#"
                onClick={() => paginate(currentPage - 1)}
              >
                Previous
              </MDBPaginationLink>
            </MDBPaginationItem>
            {pageNumbers.map((number) => (
              <MDBPaginationItem
                key={number}
                active={currentPage === number}
                className="projectlistusers-number-item"
              >
                <MDBPaginationLink href="#" onClick={() => paginate(number)}>
                  {number}
                </MDBPaginationLink>
              </MDBPaginationItem>
            ))}
            <MDBPaginationItem
              className="projectlistusers-next-item"
              disabled={currentPage >= pageNumbers.length}
            >
              <MDBPaginationLink
                href="#"
                onClick={() => paginate(currentPage + 1)}
              >
                Next
              </MDBPaginationLink>
            </MDBPaginationItem>
          </MDBPagination>
        </nav>
      </div>
    </MDBContainer>
  );
}

export default ProjectsEmploymentList;
