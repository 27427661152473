import {
  MDBBtn,
  MDBContainer,
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
  MDBTypography,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import "./masterFL.css";
import { Box, Divider, Link } from "@chakra-ui/react";
import { MDBBadge, MDBListGroup, MDBListGroupItem } from "mdb-react-ui-kit";
import { getAllConnectedUsers } from "../firebase";
import SetProjectTable from "./setprojecttable";
import { useNavigate } from "react-router-dom";
import { Activities, OnlineUser } from "../notifications/notification";
import { User2Icon } from "lucide-react";

export default function Users() {
  const [basicActive, setBasicActive] = useState("Activities");
  const [connectedUsers, setConnectedUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = getAllConnectedUsers({
      onUpdate: (users) => {
        setConnectedUsers(users);
      },
      limitCall: 4,
    });

    return () => unsubscribe(); // Cleanup on component unmount
  }, []);

  useEffect(() => {
    const unsubscribe = SetProjectTable({
      onUpdate: (projects) => {
        setProjects(projects);
      },
      limitCall: 7,
    });

    return () => unsubscribe(); // Cleanup on component unmount
  }, []);

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };
  return (
    <MDBContainer className="notificationsContainer">
      <div className="notificationsHeader">
        <div className="notificationsHeaderOne">
          <User2Icon className="notificationIcon" />
          <MDBTypography variant="h2" className="notificationTitle">
            Friends
          </MDBTypography>
        </div>
        <span className="notificationViewAll">View All</span>
      </div>

      <Box className="notificationsDivider">
        <Divider orientation="horizontal" borderColor="transparent" />
      </Box>

      <div className="notificationsSection">
        <MDBTabs pills className="mb-3 tabsMDB">
          <MDBTabsItem>
            <MDBTabsLink
              className="tabBtn"
              onClick={() => handleBasicClick("Activities")}
              active={basicActive === "Activities"}
            >
              Activities
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink
              className="tabBtn"
              onClick={() => handleBasicClick("Online")}
              active={basicActive === "Online"}
            >
              Online
            </MDBTabsLink>
          </MDBTabsItem>
        </MDBTabs>
        <MDBTabsContent className="tabsMDBContent">
          <MDBTabsPane
            style={{ width: "100%" }}
            open={basicActive === "Activities"}
          >
            <MDBListGroup className="listGroup">
              {projects.map((project) => (
                <Activities project={project} />
              ))}
            </MDBListGroup>
          </MDBTabsPane>
          <MDBTabsPane
            open={basicActive === "Online"}
            style={{ width: "100%" }}
          >
            {connectedUsers.length !== 0 ? (
              <MDBListGroup
                className="listGroup"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "2%",
                  border: "none",
                }}
              >
                {connectedUsers.map((user) => (
                  <OnlineUser key={user.id} user={user} />
                ))}
              </MDBListGroup>
            ) : (
              <MDBListGroupItem className="disconnectGroupItem">
                <div className="allDisconnect">No One Online</div>
              </MDBListGroupItem>
            )}
          </MDBTabsPane>
        </MDBTabsContent>
      </div>
      <Box className="notificationsDivider">
        <Divider orientation="horizontal" borderColor="transparent" />
      </Box>
      <div className="usersFooter"></div>
    </MDBContainer>
  );
}
