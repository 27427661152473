import React from "react";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate("/LRU");
  };

  return (
    <div className="parent-home">
      <div className="homepage-container">
        <main className="homepage-main">
          <section className="homepage-section">
            {/* <h1 className="homepage-title">Welcome to Our Platform</h1> */}
            <h1 className="homepage-h1" onClick={handleGetStarted}>
              Welcome
              <span>Welcome</span>
              <span>Welcome</span>
              <span>Get Started</span>
            </h1>
            
            {/* <button className="homepage-button" onClick={handleGetStarted}>
              Get Started
            </button> */}
            {/* <p className="homepage-description">
              Start your journey with us today. Connect, collaborate, and create
              something amazing.
            </p>
            <div className="homepage-div-span" onClick={handleGetStarted}>
              <span className="homepage-span">Get Started</span>
              <span className="homepage-span-bottom">
                Go to Connection Page
              </span>
            </div> */}
          </section>
        </main>
      </div>
    </div>
  );
};

export default HomePage;
