import { auth, db } from "../firebase";
import { useEffect, useState } from "react";
import { doc, getDoc, onSnapshot } from "firebase/firestore";

function useCurrentUser() {
  const [userData, setUserData] = useState({
    uid: "",
    firstName: "",
    lastName: "",
    role: "",
  });

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) return;

    const docRef = doc(db, "users", user.uid);

    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        setUserData({ uid: user.uid, ...docSnap.data() });
      } else {
        console.log("No such document!");
      }
    });

    return () => unsubscribe();
  }, []);

  return userData;
}

export default useCurrentUser;
