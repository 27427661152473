import React, { useState, useEffect, useMemo } from "react";
import { db, getConnectedUserById, getUserById } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  onSnapshot,
  updateDoc,
  doc,
} from "firebase/firestore";
import CryptoJS from "crypto-js";
import {
  MDBBadge,
  MDBContainer,
  MDBListGroup,
  MDBListGroupItem,
  MDBTypography,
} from "mdb-react-ui-kit";
import useCurrentUser from "../currentUser/currentuser";
import Chat from "./chat";
import "./ChatList.css";
import Loading from "../loading";
import {
  Avatar,
  AvatarBadge,
  Tooltip,
  Icon,
  Input,
  InputRightElement,
  IconButton,
  InputGroup,
  Box,
  Divider,
} from "@chakra-ui/react";
import "./chatDesign.css";
import { GrSearch, GrSend } from "react-icons/gr";
import DetailsChat from "./detailsChat";
import { SearchIcon } from "lucide-react";

function ChatList() {
  const [chats, setChats] = useState([]);
  const [users, setUsers] = useState({});
  const [lastMessages, setLastMessages] = useState({});
  const [selectedChat, setSelectedChat] = useState(null);
  const [unreadMessages, setUnreadMessages] = useState({});
  const userData = useCurrentUser();
  const [loading, setLoading] = useState(true);
  const [userDist, setUserDist] = useState("");
  const [userIdDist, setUserIdDist] = useState("");
  const [isOtherConnect, setIsOtherConnect] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const q1 = query(
          collection(db, "chats"),
          where("clientId", "==", userData.uid)
        );

        const q2 = query(
          collection(db, "chats"),
          where("leaderId", "==", userData.uid)
        );

        // Listen for real-time updates from both queries
        const unsubscribe1 = onSnapshot(q1, (querySnapshot1) => {
          const chatList1 = querySnapshot1.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const unsubscribe2 = onSnapshot(q2, async (querySnapshot2) => {
            const chatList2 = querySnapshot2.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            const allChats = [...chatList1, ...chatList2];

            setChats(allChats);

            const userIds = allChats.flatMap((chat) => [
              chat.clientId,
              chat.leaderId,
            ]);

            const uniqueUserIds = [...new Set(userIds)];
            const userPromises = uniqueUserIds.map((id) => getUserById(id));
            const userResults = await Promise.all(userPromises);

            const userDetails = {};
            uniqueUserIds.forEach((id, index) => {
              userDetails[id] = userResults[index];
            });

            setUsers(userDetails);

            allChats.forEach((chat) => {
              const messagesQuery = query(
                collection(db, "messages"),
                where("chatId", "==", chat.id),
                orderBy("timestamp", "desc")
              );

              const unsubscribeMessages = onSnapshot(
                messagesQuery,
                (querySnapshot) => {
                  let unreadCount = 0;
                  let lastMessage = "No messages yet";
                  let lastMessageTime = "00:00";

                  const messages = querySnapshot.docs.map((doc) => doc.data());

                  if (messages.length > 0) {
                    try {
                      const bytes = CryptoJS.AES.decrypt(
                        messages[0].text,
                        chat.encryptionKey
                      );
                      lastMessage =
                        bytes.toString(CryptoJS.enc.Utf8) || "No content";

                      const messageTime = new Date(
                        messages[0].timestamp.seconds * 1000
                      );
                      lastMessageTime = messageTime.toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      });
                    } catch (error) {
                      lastMessage = "Error decrypting message";
                    }
                  }

                  setLastMessages((prev) => ({
                    ...prev,
                    [chat.id]: {
                      text: decodeURIComponent(lastMessage),
                      time: lastMessageTime,
                      senderId: messages[0]?.senderId,
                    },
                  }));

                  setUnreadMessages((prev) => ({
                    ...prev,
                    [chat.id]: unreadCount,
                  }));
                }
              );

              return () => unsubscribeMessages();
            });
          });

          return () => unsubscribe2();
        });

        return () => unsubscribe1();
      } catch (error) {
        console.error("Error fetching chats: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchChats();
  }, [userData.uid]);

  useEffect(() => {
    if (chats.length > 0) {
      const unsubscribes = chats.map((chat) => {
        const otherUserId =
          userData.role === "client" ? chat.leaderId : chat.clientId;

        const userDocRef = doc(db, "users", otherUserId);

        // Listen to real-time connection status changes
        const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            const connectionHistory = userData.connectionHistory || [];
            const latestEntry = connectionHistory[connectionHistory.length - 1];

            const isConnected = latestEntry && latestEntry.type === "connect";
            setIsOtherConnect((prev) => ({ ...prev, [chat.id]: isConnected }));
          }
        });

        return unsubscribe;
      });

      // Clean up listeners when the component unmounts
      return () => {
        unsubscribes.forEach((unsubscribe) => unsubscribe());
      };
    }
  }, [chats, userData]);

  const handleSelectChat = (chat) => {
    setSelectedChat(chat);

    setUserDist(
      userData.role === "client"
        ? `${users[chat.leaderId]?.firstName || "Unknown"} ${
            users[chat.leaderId]?.lastName || ""
          }`
        : `${users[chat.clientId]?.firstName || "Unknown"} ${
            users[chat.clientId]?.lastName || ""
          }`
    );
    setUserIdDist(
      userData.role === "client" ? `${chat.leaderId}` : `${chat.clientId}`
    );

    // Mark all unread messages as read
    const messagesQuery = query(
      collection(db, "messages"),
      where("chatId", "==", chat.id),
      where("receiverId", "==", userData.uid),
      where("read", "==", false)
    );

    getDocs(messagesQuery).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        updateDoc(doc.ref, { read: true });
      });
    });

    setUnreadMessages((prev) => ({
      ...prev,
      [chat.id]: 0,
    }));
  };

  useEffect(() => {
    if (selectedChat) {
      const messagesQuery = query(
        collection(db, "messages"),
        where("chatId", "==", selectedChat.id),
        where("receiverId", "==", userData.uid),
        where("read", "==", false)
      );

      const unsubscribe = onSnapshot(messagesQuery, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          updateDoc(doc.ref, { read: true });
        });

        // Set unread messages for this chat to 0
        setUnreadMessages((prev) => ({
          ...prev,
          [selectedChat.id]: 0,
        }));
      });

      return () => unsubscribe();
    }
  }, [selectedChat, userData.uid]);

  const getChatId = (chat) => {
    return `${chat.clientId}_${chat.leaderId}`;
  };

  const filteredChats = useMemo(
    () =>
      chats
        .map((chat) => {
          const lastMessage = lastMessages[chat.id] || {};
          return {
            ...chat,
            lastMessageTime: new Date(lastMessage.time || 0), // Add last message time
          };
        })
        .filter((chat) => {
          const chatPartner =
            userData.role === "client"
              ? users[chat.leaderId]
              : users[chat.clientId];
          const chatPartnerName = `${chatPartner?.firstName || "Unknown"} ${
            chatPartner?.lastName || ""
          }`.toLowerCase();
          return chatPartnerName.includes(searchQuery.toLowerCase());
        })
        .sort((a, b) => b.lastMessageTime - a.lastMessageTime), // Sort by last message time
    [chats, users, searchQuery, userData.role, lastMessages]
  );

  return (
    <div className="chat-container">
      {loading || Object.keys(users).length === 0 ? (
        <Loading />
      ) : (
        <div className="chat-layout">
          <div className="chat-list">
            <div
              className="chat-list-header"
              style={{ justifyContent: "center" }}
            >
              <InputGroup
                size="md"
                style={{ width: "95%", height: "50%", position: "relative" }}
              >
                <Input
                  variant="unstyled"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  _placeholder={{ color: "var(--colorTwo73)", opacity: 0.8 }}
                  style={{
                    margin: "0",
                    flex: 1,
                    height: "50px",
                    fontSize: "small",
                    padding: "10px 15px",
                    borderRadius: "25px",
                    border: "2px solid var(--primary-color-card)",
                    backgroundColor: "var(--primary-bg)",
                    color: "var(--colorTwo)",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    transition: "all 0.3s ease",
                    outline: "none",
                  }}
                  _hover={{
                    borderColor: "var(--primary-color)",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  }}
                  _focus={{
                    borderColor: "var(--primary-color)",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                  }}
                />
              </InputGroup>
            </div>
            <MDBListGroup className="chat-list-group">
              {filteredChats.length === 0 ? (
                <MDBListGroupItem
                  style={{
                    width: "100%",
                    height: "100%",
                    background: "none",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  No chats available
                </MDBListGroupItem>
              ) : (
                filteredChats
                  .sort((a, b) => b.timestamp - a.timestamp)
                  .map((chat) => {
                    const otherUser =
                      userData.role === "client"
                        ? users[chat.leaderId]
                        : users[chat.clientId];
                    return (
                      <MDBListGroupItem
                        tag="button"
                        action
                        noBorders
                        type="button"
                        className={`chat-list-item ${
                          selectedChat &&
                          getChatId(chat) === getChatId(selectedChat)
                            ? "active"
                            : ""
                        }`}
                        key={getChatId(chat)}
                        onClick={() => handleSelectChat(chat)}
                      >
                        <div className="chat-item-content d-flex align-items-center">
                          <div className="avatar-container me-3 position-relative">
                            <Avatar
                              name={
                                userData.role === "client"
                                  ? `${
                                      users[chat.leaderId]?.firstName ||
                                      "Unknown"
                                    } ${users[chat.leaderId]?.lastName || ""}`
                                  : `${
                                      users[chat.clientId]?.firstName ||
                                      "Unknown"
                                    } ${users[chat.clientId]?.lastName || ""}`
                              }
                              backgroundColor="red.500"
                              src="https://bit.ly/broken-link"
                              className="avatar-img"
                            >
                              {unreadMessages[chat.id] > 0 && (
                                <Tooltip
                                  label={`${
                                    unreadMessages[chat.id]
                                  } new messages`}
                                >
                                  <div className="unread-messages-indicator">
                                    <MDBBadge className="ms-2" color="danger">
                                      {unreadMessages[chat.id]}
                                    </MDBBadge>
                                  </div>
                                </Tooltip>
                              )}
                              <AvatarBadge
                                boxSize="0.5em"
                                border="1px solid black"
                                bg={
                                  isOtherConnect[chat.id]
                                    ? "green.500"
                                    : "red.500"
                                }
                              />
                            </Avatar>
                          </div>
                          <div className="chat-details d-flex flex-column">
                            <div className="chat-name">
                              {userData.role === "client"
                                ? `${
                                    users[chat.leaderId]?.firstName || "Unknown"
                                  } ${users[chat.leaderId]?.lastName || ""}`
                                : `${
                                    users[chat.clientId]?.firstName || "Unknown"
                                  } ${users[chat.clientId]?.lastName || ""}`}
                            </div>
                            <div className="chat-last-message">
                              <span
                                style={{
                                  maxWidth: "60%",
                                  textWrap: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {lastMessages[chat.id]?.senderId ===
                                userData.uid
                                  ? "you : "
                                  : ""}
                                {lastMessages[chat.id]?.text}
                              </span>
                              <div className="chat-time">
                                {lastMessages[chat.id]?.time}
                              </div>
                            </div>
                          </div>
                        </div>
                      </MDBListGroupItem>
                    );
                  })
              )}
            </MDBListGroup>
          </div>
          <Box className="notificationsDividerVerticalWChat">
            <Divider orientation="vertical" borderColor="transparent" />
          </Box>
          <div className="chat-window">
            {selectedChat ? (
              <Chat
                leaderId={selectedChat.leaderId}
                clientId={selectedChat.clientId}
                projectId={selectedChat.projectId}
                chatId={getChatId(selectedChat)}
                chatPartnerName={userDist}
                chatPartnerId={userIdDist}
                isOtherConnect={isOtherConnect[selectedChat.id]}
              />
            ) : (
              <div className="nt">No chats available</div>
            )}
          </div>
          <Box className="notificationsDividerVerticalWChat">
            <Divider orientation="vertical" borderColor="transparent" />
          </Box>
          <div className="chat-side-details">
            {selectedChat ? (
              <DetailsChat
                leaderId={selectedChat.leaderId}
                clientId={selectedChat.clientId}
                projectId={selectedChat.projectId}
              />
            ) : (
              <div className="nt">No data available</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ChatList;
