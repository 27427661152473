import React, { useState } from "react";
import { IoEllipsisVerticalSharp } from "react-icons/io5";
import { FaRegHeart, FaRegComment } from "react-icons/fa";
import "./Test.css";
import { EyeIcon, EyeOffIcon, ScanEyeIcon } from "lucide-react";
import { MDBCard, MDBCardHeader, MDBCardBody, MDBCardFooter, MDBBtn, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdb-react-ui-kit";

function Test({
  clientName = "John Doe",
  date = "Jan 1, 2025",
  content = "This is an example of a tweet or post content. It can be short or long, but it will always look elegant!",
  minAmount = 100,
  maxAmount = 500,
  status = "Approved",
  leader = "Leader Name",
  onEdit = true,
  onDelete,
  onReport,
  hasReported = false,
}) {
  const projectData = {
    leaderName: "John Doe",
    date: "February 14, 2025",
    subject: "AI-Based Chatbot Development",
    content:
      "Develop an AI chatbot for customer support using React and Firebase.",
    developersNeeded: [
      "Frontend Developer",
      "Backend Developer",
      "AI Engineer",
    ],
    selectedDevelopers: ["Alice Smith", "Bob Johnson"],
  };
  const [selectedAction, setSelectedAction] = useState("");
  return (
    <>
      <div className="post-card creative">
        <div className="post-header">
          <div className="post-client">
            <span className="post-client-name">{clientName}</span>
            <span className="post-date">{date}</span>
          </div>
          <IoEllipsisVerticalSharp className="post-options" />
        </div>

        <div className="post-content">{content}</div>

        <div className="post-footer">
          <div className="post-footer-info">
            <span className="post-amount">
              Budget:{" "}
              <strong>
                ${minAmount} - ${maxAmount}
              </strong>
            </span>
            <span className={`post-status ${status.toLowerCase()}`}>
              {status}
            </span>
          </div>

          <div className="post-footer-actions">
            {onEdit && (
              <button onClick={onEdit} className="post-action-button edit">
                Edit
              </button>
            )}
            {onDelete && (
              <button onClick={onDelete} className="post-action-button delete">
                Delete
              </button>
            )}
            {onReport && (
              <button
                onClick={onReport}
                disabled={hasReported}
                className={`post-action-button report ${
                  hasReported ? "reported" : ""
                }`}
              >
                {hasReported ? "Reported" : "Report"}
              </button>
            )}
          </div>

          <div className="post-social-actions">
            <div className="social-action">
              <FaRegHeart className="post-icon" />
              <span className="social-count">120</span>
            </div>
          </div>
        </div>
      </div>
      <div class="notification-card">
        <div className="notification-header">
          <span className="notification-name">Name of client</span>
          <span>
            <ScanEyeIcon />
          </span>
        </div>
        <div className="notification-footer">
          <span className="notification-timing">09/10/2025</span>
          <MDBBtn
            outline
            disabled
            color={status === "approved" ? "success" : "warning"}
            size="sm"
            type="button"
            className="notificationLeaderBtn"
          >
            {status}
          </MDBBtn>
        </div>
      </div>
      <MDBCard className="shadow-lg rounded">
        {/* Header */}
        <MDBCardHeader className="bg-primary text-white text-center">
          <h5>{projectData.leaderName}</h5>
          <small>{projectData.date}</small>
        </MDBCardHeader>

        {/* Body */}
        <MDBCardBody>
          <h6 className="fw-bold">Subject:</h6>
          <p>{projectData.subject}</p>

          <h6 className="fw-bold">Description:</h6>
          <p>{projectData.content}</p>

          <h6 className="fw-bold">Developers Needed:</h6>
          <ul>
            {projectData.developersNeeded.map((role, index) => (
              <li key={index}>{role}</li>
            ))}
          </ul>

          <h6 className="fw-bold">Selected Developers:</h6>
          <ul>
            {projectData.selectedDevelopers.length > 0 ? (
              projectData.selectedDevelopers.map((dev, index) => <li key={index}>{dev}</li>)
            ) : (
              <p className="text-muted">No developers selected yet.</p>
            )}
          </ul>
        </MDBCardBody>

        {/* Footer */}
        <MDBCardFooter className="d-flex justify-content-between">
          <MDBDropdown>
            <MDBDropdownToggle color="secondary">
              {selectedAction || "Select Action"}
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              <MDBDropdownItem
                link
                onClick={() => setSelectedAction("Approve")}
              >
                Approve
              </MDBDropdownItem>
              <MDBDropdownItem link onClick={() => setSelectedAction("Reject")}>
                Reject
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>

          <MDBBtn color="success" disabled={!selectedAction}>
            Confirm
          </MDBBtn>
        </MDBCardFooter>
      </MDBCard>
    </>
  );
}

export default Test;
