import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { MDBBtn } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import {
  checkApprovementProgrammer,
  checkWorkingProjects,
  db,
  deleteWorkingprogrammer,
  getProjectById,
  getUserById,
} from "../firebase";
import Loading from "../loading";
import useCurrentUser from "../currentUser/currentuser";
import { addDoc, collection, deleteDoc, doc, where } from "firebase/firestore";
import { Navigate } from "react-router-dom";
import { MinusIcon } from "lucide-react";
import { Tooltip } from "recharts";

export const ProjectDetails = ({
  subject,
  body,
  clientId,
  leaderId,
  projectId,
  forms,
  wpId,
  dateWork,
}) => {
  const [value, setValue] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [checkType, setCheckType] = useState(false); // false means not requested yet
  const [leaderData, setLeaderData] = useState([]);
  const userData = useCurrentUser();
  const [checkApprovement, setCheckApprovement] = useState(null);

  useEffect(() => {
    const fetchProject = async () => {
      const project = await getProjectById(projectId);
      setSelectedProject(project);
      setLeaderData(await getUserById(leaderId));
    };

    if (projectId) fetchProject();

    const unsubscribe = checkApprovementProgrammer(
      userData.uid,
      wpId,
      projectId,
      setCheckApprovement
    );

    // Listen for updates to check if the programmer has already requested the work
    const unsubscribeWork = checkWorkingProjects(
      wpId,
      userData.uid,
      setCheckType
    );

    if (checkType && checkType.length !== 0) {
      setValue(checkType[0].type);
    }

    // Clean up the listener when the component unmounts
    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe(); // Correct way to clean up the listener
      }
      unsubscribeWork();
    };
  }, [projectId, wpId, userData.uid]); // Runs when `projectId`, `wpId`, or `userData.uid` changes

  const handleRequestWork = async () => {
    if (value === "") {
      return;
    }
    try {
      await addDoc(collection(db, "requestworkingprojects"), {
        programmerId: userData.uid,
        projectId: projectId,
        workId: wpId,
        type: value,
        time: new Date(),
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleCancelRequest = async () => {
    deleteWorkingprogrammer(wpId, userData.uid);
  };

  if (
    selectedProject === null ||
    !userData ||
    leaderData.length === 0 ||
    checkApprovement === null
  ) {
    return <Loading />;
  }

  return (
    <Box className="post-card creative">
      <Box className="post-header">
        <div className="post-client">
          <span
            className="post-client-name"
            onClick={() => {
              Navigate(`/profile/${leaderId}`);
            }}
          >
            {`${leaderData?.firstName} ${leaderData?.lastName}`}
          </span>
          <span className="post-date">
            {dateWork &&
              new Date(
                dateWork.seconds * 1000 + dateWork.nanoseconds / 1000000
              ).toLocaleDateString("en-US", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })}
          </span>
        </div>
      </Box>
      <Box className="post-content">
        <Text fontWeight="bold" mb={2}>
          Subject :
        </Text>
        <Box>
          <Text className="projectusers-subject-text">
            {selectedProject?.subject}
          </Text>
        </Box>
        {/* <Text fontWeight="bold" mb={2}>
          Content :
        </Text>
        <Box>
          <Text className="projectusers-body-text">
            {selectedProject?.body}
          </Text>
        </Box> */}
        {/* <Text fontWeight="bold" mb={2}>
          Developer needed :
        </Text>
        <Box as="ul" listStyleType="circle" w="100%">
          {forms.map((item) => (
            <li
              key={item.type}
              style={{
                fontSize: "medium",
                textDecoration:
                  item.incharge === true ? "line-through" : "none",
                color: item.incharge === true ? "green" : "white",
              }}
            >
              {item.type} : {item.message}
            </li>
          ))}
        </Box> */}
        <Text fontWeight="bold" mb={2}>
          Select
        </Text>
        <RadioGroup
          onChange={setValue}
          value={value}
          flex={1}
          display="flex"
          width={"100%"}
        >
          <Stack
            direction="column"
            display={"flex"}
            flexDirection={"row"}
            flex={1}
            width={"100%"}
          >
            {forms.map((item) => (
              <Box
                flex={0.33}
                key={item.type}
                p={4}
                borderColor={
                  value === item.type ||
                  (checkType.length !== 0 && checkType[0]?.type === item.type)
                    ? "white"
                    : "whiteAlpha.300"
                }
                borderWidth="1px"
                borderRadius="md"
                cursor={
                  item.incharge === false && checkType.length === 0
                    ? "pointer"
                    : "not-allowed"
                }
                display="flex"
                justifyContent="center"
                alignItems="center"
                bg={
                  value === item.type
                    ? "whiteAlpha.100"
                    : item.incharge === true
                    ? "#002500"
                    : "black"
                }
                onClick={() =>
                  item.incharge === false && checkType.length === 0
                    ? setValue(item.type)
                    : null
                }
                transition="all 0.2s ease-in-out"
                position="relative"
                _before={{
                  content: `"${item.message}"`,
                  position: "absolute",
                  transform: "translateX(-50%) scale(1.1)",
                  opacity: 0,
                  fontSize: "xs",
                  textAlign: "center",
                  color: "white",
                  zIndex: "2",
                  bg: "black",
                  padding: "2px 5px",
                  width: "90%",
                  top: "60px", // Position above the box
                  left: "50%",
                  borderRadius: "md",
                  whiteSpace: "normal",
                  transition: "all 0.3s ease-in-out",
                }}
                _hover={{
                  "::before": {
                    content: `"${item.message}"`,
                    position: "absolute",
                    top: "55px", // Position above the box
                    left: "50%",
                    transform: "translateX(-50%) scale(1.1)",
                    opacity: 1,
                    fontSize: "xs",
                    textAlign: "center",
                    color: "white",
                    zIndex: "2",
                    bg: "black",
                    padding: "2px 5px",
                    width: "90%",
                    borderRadius: "md",
                    whiteSpace: "normal",
                    transition: "all 0.3s ease-in-out", // Smooth transition for opacity and scale
                  },
                }}
                _notHover={{
                  "::before": {
                    opacity: 0, // Hide message when not hovered
                    transform: "translateX(-50%) scale(0.9)",
                    transition: "opacity 0.3s ease, transform 0.3s ease", // Slightly reduce size when not hovered
                  },
                }}
              >
                <Text
                  textAlign="center"
                  fontSize="x-small"
                  margin={0}
                  fontWeight="bold"
                >
                {console.log(item.message)}
                  {item.type}
                </Text>
              </Box>
            ))}
          </Stack>
        </RadioGroup>
      </Box>
      <Box
        className="post-footer"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        {checkApprovement === true ? (
          <h4>
            <span className="badge badge-success">Success</span>
          </h4>
        ) : (
          <>
            <MDBBtn
              type="button"
              disabled={!value || checkType.length !== 0} // Disable if already requested
              style={{
                cursor: `${
                  !value || checkType.length !== 0 ? "not-allowed" : "pointer"
                }`,
              }}
              color="success"
              className="me-1"
              outline
              onClick={() => {
                handleRequestWork();
              }}
            >
              Request
            </MDBBtn>
            {checkType.length !== 0 && (
              <MDBBtn
                type="button"
                color="danger"
                className="me-1"
                outline
                onClick={handleCancelRequest}
              >
                Cancel
              </MDBBtn>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
