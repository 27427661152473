import React, { useEffect, useState } from "react";
import Loading from "../loading";
import {
  boolenWorkingProject,
  db,
  getProjectById,
  getProjectByIdRealTime,
  getUserByIdRealTime,
  setProjectById,
} from "../firebase";
import {
  Box,
  Button,
  Code,
  Divider,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import {
  MDBBtn,
  MDBInput,
  MDBModal,
  MDBModalContent,
  MDBModalDialog,
  MDBTypography,
  MDBValidation,
  MDBValidationItem,
} from "mdb-react-ui-kit";
import { IoCheckmarkOutline } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import useCurrentUser from "../currentUser/currentuser";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";

const DetailsChat = ({ leaderId, clientId, projectId }) => {
  const [leader, setLeader] = useState(null);
  const [client, setClient] = useState(null);
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [exist, setExist] = useState(false);
  const [open, setOpen] = useState(false);
  const [boolWorkingProject, setBoolWorkingProject] = useState(true);

  const userData = useCurrentUser();
  const [minAmount, setMinAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState("");
  const [fixAmount, setFixAmount] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const handleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    let unsubscribeLeader, unsubscribeClient, unsubscribeProject;

    // Reset states to null when new chat is selected to avoid stale data
    setLeader(null);
    setClient(null);
    setProject(null);
    setLoading(true);

    // Fetch leader, client, and project in real-time
    unsubscribeLeader = getUserByIdRealTime(leaderId, setLeader);
    unsubscribeClient = getUserByIdRealTime(clientId, setClient);
    unsubscribeProject = getProjectByIdRealTime(projectId, setProject);

    return () => {
      unsubscribeLeader?.();
      unsubscribeClient?.();
      unsubscribeProject?.();
    };
  }, [leaderId, clientId, projectId]);

  // Check if all data is loaded
  useEffect(() => {
    if (leader && client && project) {
      setExist(true);
      setMinAmount(project.minAmount);
      setMaxAmount(project.maxAmount);
      setFixAmount(project.fixAmount);
      setLoading(false);
    } else {
      setExist(false);
      setLoading(false);
    }
  }, [leader, client, project]);

  useEffect(() => {
    const fetchProject = async () => {
      if (projectId) {
        const project = await getProjectById(projectId);
        setSelectedProject(project);
      }
    };
    fetchProject();
  }, [projectId]);

  useEffect(() => {
    if (!selectedProject?.leaderId || !selectedProject?.userId || !projectId)
      return;

    const unsubscribe = boolenWorkingProject(
      selectedProject.leaderId,
      selectedProject.userId,
      projectId,
      setBoolWorkingProject
    );

    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe(); // Cleanup
      }
    };
  }, [selectedProject?.leaderId, selectedProject?.userId, projectId]);

  const handleSave = async () => {
    try {
      await setProjectById(projectId, parseFloat(fixAmount));
      setProject({
        ...project,
        fixAmount: parseFloat(fixAmount),
      });
      setFixAmount(project.fixAmount);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating project details: ", error);
    }
  };

  const handleCancel = () => {
    setFixAmount(project.fixAmount);
    setIsEditing(false);
  };

  const handleCancelProject = async () => {
    const batch = writeBatch(db);

    try {
      // 1. Query the relevant chat document(s) by projectId
      const chatsRef = collection(db, "chats");
      const chatsQuery = query(chatsRef, where("projectId", "==", projectId));
      const chatDocs = await getDocs(chatsQuery);

      // 2. Update chat documents to set active: false
      chatDocs.forEach((chatDoc) => {
        batch.update(chatDoc.ref, { active: false });
      });

      // 3. Update the project document to change its work status and reset the leader
      const projectRef = doc(db, "projects", projectId);
      batch.update(projectRef, {
        work: "still",
        status: "waiting",
        leaderId: "",
        leader: "",
        fixAmount: "",
      });

      // 4. Update the leader's status in the 'users' collection to working: false
      const leaderId = chatDocs.docs[0].data().leaderId; // Assuming only one leader per project
      const leaderRef = doc(db, "users", leaderId);
      batch.update(leaderRef, { working: false, projectWorkingFor: "" });

      // 5. Decrement AProjects for the user who made the project
      const userRef = doc(db, "users", clientId);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const currentAProjects = userSnap.data().AProjects || 0;
        const newAProjects = Math.max(0, currentAProjects - 1); // Ensure it doesn't go below 0
        batch.update(userRef, { AProjects: newAProjects });
      }

      // Commit the batch update
      await batch.commit();
    } catch (error) {
      console.error("Error updating project and related data: ", error);
    }
  };

  if (loading) return <Loading />;
  if (!exist && !loading) return <>No data available</>;

  return (
    <>
      <div className="chat-list-header detail">
        <MDBTypography variant="h2" className="h2DetailTitle">
          About
        </MDBTypography>
      </div>

      <Box className="notificationsDivider">
        <Divider orientation="horizontal" borderColor="transparent" />
      </Box>

      <div className="details-chat-section">
        <span className="details-project-body">
          <span className="details-project-subject">Client:</span>
          {client.firstName} {client.lastName}
        </span>
        <span className="details-project-body">
          <span className="details-project-subject">Leader:</span>
          {leader.firstName} {leader.lastName}
        </span>
        <span className="details-project-body">
          <span className="details-project-subject">Subject:</span>
          {project.subject}
        </span>
        <span className="details-project-body">
          <span className="details-project-subject">Body:</span>
          {project.body}
        </span>
        <span className="details-project-body">
          <span className="details-project-subject">Date:</span>
          {new Date(
            project.publicationDate.seconds * 1000 +
              project.publicationDate.nanoseconds / 1000000
          ).toLocaleDateString("en-US", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          })}
        </span>
        <span className="details-project-body">
          <span className="details-project-subject">Amount:</span>
          Max: {project.maxAmount}
          <br />
          Min: {project.minAmount}
        </span>
        <span className="details-project-body">
          <span className="details-project-subject">Fix Amount:</span>
          {project.fixAmount || "-"}
        </span>
        <span className="details-project-body">
          <span className="details-project-subject">Status:</span>
          {project.status}
        </span>
        <span className="details-project-body">
          <span className="details-project-subject">Work:</span>
          {project.work}
        </span>
      </div>
      <Box className="notificationsDivider">
        <Divider orientation="horizontal" borderColor="transparent" />
      </Box>
      <div className="details-chat-footer">
        <div className="editableDiv">
          <MDBValidation className="row g-3" isValidated>
            <MDBValidationItem
              className="col-md-6"
              feedback={`The minimum amount should be ${minAmount} - The maximum amount should be ${maxAmount}.`}
              invalid
            >
              <MDBInput
                label="Fix Amount"
                labelStyle={{ color: "#fff" }}
                type="number"
                size="sm"
                autoComplete="off"
                id="validationCustom03"
                disabled={!isEditing}
                required
                max={maxAmount}
                min={minAmount}
                value={fixAmount}
                name="Min Amount"
                width="10%"
                onChange={(e) => setFixAmount(e.target.value)}
              />
            </MDBValidationItem>
          </MDBValidation>
          {boolWorkingProject ? (
            <Code colorScheme="black" children="starting project" />
          ) : !isEditing &&
            userData.role === "client" &&
            project.start === false &&
            project.status === "approved" ? (
            <MDBBtn
              style={{ width: "max-content" }}
              size="sm"
              onClick={() => setIsEditing(true)}
            >
              Edit
            </MDBBtn>
          ) : userData.role === "client" &&
            userData.uid === clientId &&
            project.status === "approved" ? (
            <Flex justifyContent="center" alignItems="center" gap="5px">
              <MDBBtn
                style={{ width: "max-content" }}
                outline
                size="sm"
                color="success"
                onClick={handleSave}
              >
                Save
              </MDBBtn>
              <MDBBtn
                outline
                style={{ height: "max-content" }}
                size="sm"
                color="danger"
                onClick={handleCancel}
              >
                Cancel
              </MDBBtn>
            </Flex>
          ) : (
            userData.role === "groupleader" &&
            userData.uid === leaderId &&
            project.start === false &&
            project.status === "approved" && (
              <Flex justifyContent="flex-end" alignItems="center" gap="5px">
                <MDBBtn
                  size="sm"
                  className="leadercheck"
                  tag="a"
                  color="success"
                  outline
                  onClick={() => handleOpen()}
                >
                  <IoCheckmarkOutline />
                </MDBBtn>
                {open && (
                  <AcceptedProjectModal
                    open={open}
                    handleOpen={handleOpen}
                    pId={projectId}
                    selectedProject={selectedProject}
                  />
                )}
                <MDBBtn
                  size="sm"
                  onClick={() => {
                    handleCancelProject();
                  }}
                  className="leadercheck"
                  tag="a"
                  color="danger"
                  outline
                >
                  <IoMdClose />
                </MDBBtn>
              </Flex>
            )
          )}
        </div>
      </div>
    </>
  );
};

export const AcceptedProjectModal = ({
  open,
  handleOpen,
  pId,
  selectedProject,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [numberProgrammers, setNumberProgrammers] = useState(0);

  const nextTab = () =>
    setTabIndex((prevIndex) => (prevIndex < 1 ? prevIndex + 1 : prevIndex));
  const prevTab = () =>
    setTabIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));

  const handleAccept = async () => {
    if (numberProgrammers < 1 || numberProgrammers > 6) {
      alert("Number of programmers must be between 1 and 6.");
      return;
    }

    const forms = [];
    for (let i = 0; i < numberProgrammers; i++) {
      const typeInput = document.getElementById(`formControlSm${i}`);
      const messageInput = document.getElementById(`textAreaExample${i}`);

      if (!typeInput?.value.trim() || !messageInput?.value.trim()) {
        alert(`Please fill out all fields for Programmer ${i + 1}.`);
        return;
      }

      forms.push({
        type: typeInput.value.trim(),
        incharge: false, // Default to false, can be updated later
        idprogrammer: "",
        message: messageInput.value.trim(),
      });
    }

    try {
      console.log(selectedProject, pId, forms);
      await addDoc(collection(db, "workingprojects"), {
        clientId: selectedProject?.userId,
        leaderId: selectedProject?.leaderId,
        projectId: pId,
        forms,
        fixAmount: selectedProject?.fixAmount,
        time: new Date(),
      });
      const projectDocRef = doc(db, "projects", pId);
      await updateDoc(projectDocRef, {
        inwork: true,
      });
      handleOpen();
    } catch (error) {
      console.error("Error saving project:", error);
      alert("An error occurred while accepting the project.");
    }
  };

  if (!selectedProject) return <Loading />;

  return (
    <MDBModal open={open} tabIndex="-1">
      <MDBModalDialog size="lg">
        <MDBModalContent className="notificationsContentModal">
          <Box padding="3%">
            <Tabs index={tabIndex} isLazy>
              <TabPanels>
                <TabPanel>
                  <div className="col-md-6">
                    <div className="form-outline" data-mdb-input-init>
                      <input
                        value={numberProgrammers}
                        type="number"
                        className={`form-control ${
                          numberProgrammers > 6 || numberProgrammers < 1
                            ? "is-invalid"
                            : "is-valid"
                        }`}
                        id="validationServer05"
                        required
                        onChange={(e) =>
                          setNumberProgrammers(Number(e.target.value))
                        }
                      />
                      <label
                        htmlFor="validationServer05"
                        className="form-label"
                      >
                        Number of programmers
                      </label>
                      <div
                        className={
                          numberProgrammers > 6 || numberProgrammers < 1
                            ? "invalid-feedback"
                            : "valid-feedback"
                        }
                      >
                        Must be between 1-6 programmers.
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  {numberProgrammers > 0 &&
                    Array.from({ length: numberProgrammers }).map(
                      (_, index) => (
                        <div key={index}>
                          <h2>Programmer {index + 1}</h2>
                          <div
                            className="form-outline"
                            style={{ margin: "3% 0" }}
                            data-mdb-input-init
                          >
                            <input
                              type="text"
                              id={`formControlSm${index}`}
                              className="form-control form-control-sm"
                            />
                            <label
                              className="form-label"
                              htmlFor={`formControlSm${index}`}
                            >
                              Ptype of programming
                            </label>
                          </div>
                          <div className="form-outline" data-mdb-input-init>
                            <textarea
                              className="form-control"
                              id={`textAreaExample${index}`}
                              rows="4"
                            ></textarea>
                            <label
                              className="form-label"
                              htmlFor={`textAreaExample${index}`}
                            >
                              Message
                            </label>
                          </div>
                        </div>
                      )
                    )}
                </TabPanel>
              </TabPanels>
            </Tabs>

            <Box display="flex" justifyContent="space-between" flex={1} mt={4}>
              <Box display="flex" gap="5%" flex={0.5}>
                <MDBBtn
                  onClick={prevTab}
                  color="primary"
                  outline={tabIndex === 0}
                  style={{ fontSize: "x-small" }}
                  disabled={tabIndex === 0}
                >
                  Previous
                </MDBBtn>
                {tabIndex < 1 ? (
                  <MDBBtn
                    onClick={nextTab}
                    color="primary"
                    style={{ fontSize: "x-small" }}
                    outline={tabIndex === 1}
                    disabled={
                      tabIndex === 1 ||
                      numberProgrammers > 6 ||
                      numberProgrammers < 1
                    }
                  >
                    Next
                  </MDBBtn>
                ) : (
                  <MDBBtn
                    color="success"
                    style={{ fontSize: "x-small" }}
                    onClick={handleAccept}
                  >
                    Confirm
                  </MDBBtn>
                )}
              </Box>
              <MDBBtn
                color="danger"
                outline
                style={{ fontSize: "x-small" }}
                onClick={handleOpen}
              >
                Close
              </MDBBtn>
            </Box>
          </Box>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default DetailsChat;
