import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBListGroup,
  MDBListGroupItem,
  MDBTypography,
} from "mdb-react-ui-kit";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  limit,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase";
import Notification, { ApprovedProject, NotificationProgrammer } from "./notification";
import useCurrentUser from "../currentUser/currentuser";
import NotificationSended from "./notificationSended";
import { BiSolidNotification } from "react-icons/bi";
import { Box, Divider } from "@chakra-ui/react";
import NotificationListModel from "./notificationListModel";
import "./notification.css";
import Loading from "../loading";
import { TbNumber5Small } from "react-icons/tb";

export default function NotificationList() {
  const [notifications, setNotifications] = useState([]);
  const [notificationsSended, setNotificationsSended] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Renaming for clarity
  const Cuser = useCurrentUser();

  useEffect(() => {
    const fetchNotifications = () => {
      let notificationsCollection = collection(db, "notifications");
      notificationsCollection = query(
        notificationsCollection,
        orderBy("notificationDate", "desc"),
        limit(5)
      );

      // Real-time listener for notifications
      const unsubscribeNotifications = onSnapshot(
        notificationsCollection,
        async (notificationsSnapshot) => {
          const notificationsList = [];

          for (const notificationDoc of notificationsSnapshot.docs) {
            const notificationData = notificationDoc.data();
            const projectDoc = await getDoc(
              doc(db, "projects", notificationData.projectId)
            );

            if (projectDoc.exists()) {
              const projectData = projectDoc.data();
              const publicationDate = projectData.publicationDate
                ? new Date(
                    projectData.publicationDate.seconds * 1000
                  ).toLocaleDateString()
                : "No date";

              notificationsList.push({
                id: notificationDoc.id,
                userName: `${projectData.firstName} ${projectData.lastName}`,
                publicationDate,
                status: projectData.status,
                body: projectData.body,
                leader: projectData.leader,
                pId: notificationData.projectId,
              });
            }
          }

          setNotifications(notificationsList);
        }
      );

      return unsubscribeNotifications;
    };

    const fetchNotificationsSended = () => {
      let notificationsSendedCollection = collection(db, "notificationsSended");
      notificationsSendedCollection = query(
        notificationsSendedCollection,
        limit(3)
      );

      // Real-time listener for notificationsSended
      const unsubscribeNotificationsSended = onSnapshot(
        notificationsSendedCollection,
        (notificationsSnapshot) => {
          const notificationsList = [];

          notificationsSnapshot.forEach((notificationDoc) => {
            const notificationData = notificationDoc.data();

            if (notificationData.to === Cuser.uid && Cuser.role === "client") {
              notificationsList.push({
                projectId: notificationData.projectId,
                to: notificationData.to,
                checked: notificationData.checked,
              });
            }
          });

          setNotificationsSended(notificationsList);
        }
      );

      return unsubscribeNotificationsSended;
    };

    const unsubscribe1 = fetchNotifications();
    const unsubscribe2 = fetchNotificationsSended();

    setIsLoading(false); // End loading once both listeners are active

    return () => {
      unsubscribe1();
      unsubscribe2();
    };
  }, [Cuser]);

  const [optSmModal, setOptSmModal] = useState(false);

  const toggleOpen = () => setOptSmModal(!optSmModal);

  return (
    <MDBContainer className="notificationsContainer">
      <div className="notificationsHeader">
        <div className="notificationsHeaderOne">
          <MDBTypography variant="h2" className="notificationTitle">
            Notification
          </MDBTypography>
        </div>
      </div>

      <Box className="notificationsDivider">
        <Divider orientation="horizontal" borderColor="transparent" />
      </Box>

      <div className="notificationsSection">
        {isLoading &&
        (notifications.length === 0 || notificationsSended.length === 0) ? (
          <Loading />
        ) : (
          <span className="notificationViewAll" onClick={toggleOpen}>
            View All
          </span>
        )}
        <MDBListGroup className="list-group-not">
          {isLoading &&
          notifications.length === 0 &&
          notificationsSended.length === 0 ? (
            <>
              <Loading />
            </>
          ) : Cuser.role !== "client" && notifications.length !== 0 ? (
            notifications.map((notification) => (
              <MDBListGroupItem
                key={notification.id}
                className="d-flex justify-content-between align-items-center yesNotificationDiv listGroupItem"
              >
                {Cuser.role === "groupleader" && (
                  <Notification
                    userName={notification.userName}
                    publicationDate={notification.publicationDate}
                    status={notification.status}
                    leader={notification.leader}
                    pId={notification.pId}
                  />
                )}
              </MDBListGroupItem>
            ))
          ) : Cuser.role === "client" && notificationsSended.length !== 0 ? (
            notificationsSended.map((notificationSended) => (
              <MDBListGroupItem
                key={notificationSended.projectId}
                className="d-flex justify-content-between align-items-center yesNotificationDiv listGroupItem"
              >
                <NotificationSended
                  to={notificationSended.to}
                  projectId={notificationSended.projectId}
                  checked={notificationSended.checked}
                />
              </MDBListGroupItem>
            ))
          ) : Cuser.role === "client" && notificationsSended.length === 0 ? (
            <div className="noNotificationsDiv">
              <MDBTypography>No Notifications Available</MDBTypography>
              <figcaption className="blockquote-footer mb-0">
                It seems there are no notifications to display at the moment.
              </figcaption>
            </div>
          ) : Cuser.role !== "client" && notifications.length === 0 ? (
            <div className="noNotificationsDiv">
              <MDBTypography>No Notifications Available</MDBTypography>
              <figcaption className="blockquote-footer mb-0">
                It seems there are no notifications to display at the moment.
              </figcaption>
            </div>
          ) : Cuser.role !== "programmer" ? (
            <NotificationProgrammer />
          ) : (
            TbNumber5Small
          )}
        </MDBListGroup>
      </div>

      <Box className="notificationsDivider">
        <Divider orientation="horizontal" borderColor="transparent" />
      </Box>

      <div className="notificationsFooter">
        {Cuser.role === "groupleader" && Cuser.working === true && (
          <ApprovedProject projectId={Cuser.projectWorkingFor} />
        )}
      </div>
      <NotificationListModel optSmModal={optSmModal} toggleOpen={toggleOpen} />
    </MDBContainer>
  );
}
