import { MDBContainer, MDBPagination, MDBPaginationItem, MDBPaginationLink } from "mdb-react-ui-kit";
import { ProjectDetails } from "./projectdetails";
import { useEffect, useState } from "react";
import { getProjectById, getWorkingProjects, getWorkingProjectsSnapshot } from "../firebase";
import Loading from "../loading";

function ProjectsWorkList() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1); // current page
  const [projectsPerPage] = useState(12); // number of projects per page
  const [pageWindowSize] = useState(5); // number of visible page links
  
  useEffect(() => {
    const unsubscribe = getWorkingProjectsSnapshot(
      (projectData) => {
        setProjects(projectData);
        setLoading(false);
      },
      (error) => {
        setError("Failed to load projects.");
        setLoading(false);
      }
    );
  
    return () => unsubscribe(); // Cleanup function
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  // Pagination logic
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = projects.slice(indexOfFirstProject, indexOfLastProject);

  const totalPages = Math.ceil(projects.length / projectsPerPage);
  
  const startPage = Math.max(1, currentPage - Math.floor(pageWindowSize / 2));
  const endPage = Math.min(totalPages, startPage + pageWindowSize - 1);
  const pageNumbers = [];
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <MDBContainer className="projectlistusers-container" style={{justifyContent: "space-between"}}>
      {currentProjects.map((project) => (
        <ProjectDetails
          key={project.id}
          subject={"test subject"}
          body={"test body"}
          clientId={project.clientId}
          leaderId={project.leaderId}
          projectId={project.projectId}
          forms={project.forms}
          wpId={project.id}
          dateWork={project.time}
        />
      ))}

      {/* Pagination Controls */}
      <nav aria-label="..." style={{alignSelf: "center"}}>
        <MDBPagination size="md" className="mb-0" style={{display: "flex", gap: "4%"}}>
          <MDBPaginationItem disabled={currentPage <= 1}>
            <MDBPaginationLink href="#" onClick={() => paginate(currentPage - 1)}>
              Previous
            </MDBPaginationLink>
          </MDBPaginationItem>
          
          {pageNumbers.map((number) => (
            <MDBPaginationItem key={number} active={currentPage === number}>
              <MDBPaginationLink href="#" onClick={() => paginate(number)}>
                {number}
              </MDBPaginationLink>
            </MDBPaginationItem>
          ))}
          
          <MDBPaginationItem disabled={currentPage >= totalPages}>
            <MDBPaginationLink href="#" onClick={() => paginate(currentPage + 1)}>
              Next
            </MDBPaginationLink>
          </MDBPaginationItem>
        </MDBPagination>
      </nav>
    </MDBContainer>
  );
}

export default ProjectsWorkList;
