import React, { useState, useEffect, useRef } from "react";
import { db, getProjectById, setProjectById } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  Timestamp,
  onSnapshot,
  orderBy,
  doc,
  getDoc,
  updateDoc,
  setDoc,
  writeBatch,
} from "firebase/firestore";
import { IoMdClose } from "react-icons/io";
import { IoCheckmarkOutline } from "react-icons/io5";
import {
  MDBContainer,
  MDBInput,
  MDBBtn,
  MDBListGroup,
  MDBListGroupItem,
  MDBTypography,
  MDBInputGroup,
  MDBIcon,
  MDBValidation,
  MDBValidationItem,
} from "mdb-react-ui-kit";
import {
  Input,
  IconButton,
  InputGroup,
  InputRightElement,
  Avatar,
  AbsoluteCenter,
  Spinner,
  Skeleton,
  Flex,
  Grid,
  Icon,
} from "@chakra-ui/react";
import { IoCheckmark, IoCheckmarkDone } from "react-icons/io5";
import useCurrentUser from "../currentUser/currentuser";
import Loading from "../loading";
import {
  CheckIcon,
  ChevronDownIcon,
  EditIcon,
  LucideLoader,
  ShieldCloseIcon,
  SidebarCloseIcon,
} from "lucide-react";
import CryptoJS from "crypto-js";
import { GrSend } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import Typing from "./typing";

function Chat({
  leaderId,
  clientId,
  projectId,
  chatId,
  chatPartnerName,
  chatPartnerId,
  isOtherConnect,
}) {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const userData = useCurrentUser();
  const [loading, setLoading] = useState(true);
  const [sendLoading, setSendLoading] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [otherUserTyping, setOtherUserTyping] = useState(false);
  const messageEndRef = useRef(null);
  const listContainerRef = useRef(null);
  const [encryptionKey, setEncryptionKey] = useState(null);
  const [chatActive, setChatActive] = useState(true);
  const [projectData, setProjectData] = useState(null);
  const [error, setError] = useState(null);
  const [deletedTexts, setDeletedTexts] = useState(false);
  const navigate = useNavigate();

  // Fetch encryption key and messages
  useEffect(() => {
    const fetchEncryptionKey = () => {
      try {
        const chatDocRef = doc(db, "chats", chatId);

        const unsubscribeChat = onSnapshot(chatDocRef, (chatDoc) => {
          if (chatDoc.exists()) {
            const data = chatDoc.data();
            setEncryptionKey(data.encryptionKey);
            setChatActive(data.active);
          } else {
            console.error("Chat does not exist!");
          }
        });

        return unsubscribeChat;
      } catch (error) {
        console.error("Error fetching encryption key: ", error);
      }
    };

    const unsubscribe = fetchEncryptionKey();

    return () => {
      unsubscribe();
    };
  }, [chatId]);

  useEffect(() => {
    if (!encryptionKey) return;

    const q = query(
      collection(db, "messages"),
      where("chatId", "==", chatId),
      orderBy("timestamp", "asc")
    );
    getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        updateDoc(doc.ref, { read: true });
      });
    });

    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const messageList = [];
        querySnapshot.forEach((doc) => {
          const encryptedData = doc.data();
          try {
            const bytes = CryptoJS.AES.decrypt(
              encryptedData.text,
              encryptionKey
            );
            const decryptedText = decodeURIComponent(
              bytes.toString(CryptoJS.enc.Utf8)
            );
            if (decryptedText == "") {
              setDeletedTexts(true);
            } else {
              messageList.push({
                ...encryptedData,
                text: decryptedText,
              });
            }
          } catch (error) {
            console.error("Decryption error: ", error);
            // Optionally, handle the error gracefully in the UI
          }
        });
        setMessages(messageList);
        setLoading(false);
        scrollToBottom();
      },
      (error) => {
        console.error("Error fetching messages: ", error);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [chatId, encryptionKey]);

  // Update typing status
  useEffect(() => {
    const updateTypingStatus = async (isTyping) => {
      if (chatId && userData?.uid) {
        const chatDocRef = doc(db, "chats", chatId);
        const fieldPath = `typing.${userData.uid}`;

        try {
          await updateDoc(chatDocRef, {
            [fieldPath]: isTyping,
          });
        } catch (error) {
          console.error("Error updating typing status: ", error);
        }
      }
    };

    // Update typing status when new message changes
    updateTypingStatus(newMessage.length > 0);

    return () => {
      // Set typing status to false when component unmounts or user stops typing
      if (userData?.uid) {
        updateTypingStatus(false);
      }
    };
  }, [newMessage, chatId, userData?.uid]);

  // Listen to typing status in real-time
  useEffect(() => {
    if (chatId) {
      const chatDocRef = doc(db, "chats", chatId);

      // Set up real-time listener for typing status updates
      const unsubscribeTyping = onSnapshot(chatDocRef, (chatDoc) => {
        if (chatDoc.exists()) {
          const data = chatDoc.data();
          if (data.typing) {
            // Access the typing status of the other user (you can customize the behavior)
            const otherUserTyping = Object.entries(data.typing).find(
              ([uid]) => uid !== userData?.uid && data.typing[uid]
            );
          }
        }
      });

      return () => {
        unsubscribeTyping(); // Unsubscribe from the real-time listener when component unmounts
      };
    }
  }, [chatId, userData?.uid]);

  // Listen for other user's typing status
  useEffect(() => {
    if (chatId && userData?.uid) {
      const chatDocRef = doc(db, "chats", chatId);

      const unsubscribe = onSnapshot(chatDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const typingData = docSnapshot.data().typing || {};
          const otherUserId = userData.uid === clientId ? leaderId : clientId;
          setOtherUserTyping(typingData[otherUserId] || false);
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, [chatId, userData?.uid, clientId, leaderId]);

  const handleSend = async () => {
    if (!newMessage.trim() || !encryptionKey) return;

    try {
      setSendLoading(true);
      const encryptedText = CryptoJS.AES.encrypt(
        encodeURIComponent(newMessage),
        encryptionKey
      ).toString();

      await addDoc(collection(db, "messages"), {
        chatId: chatId,
        senderId: userData.uid === clientId ? clientId : leaderId,
        receiverId: userData.uid === clientId ? leaderId : clientId,
        text: encryptedText,
        timestamp: Timestamp.fromDate(new Date()),
        read: false,
      });

      setNewMessage("");
      scrollToBottom();
    } catch (error) {
      console.error("Error sending message: ", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (newMessage) {
        handleSend();
      }
    }
  };

  const getMessageClass = (messages, index, userId) => {
    const currentMessage = messages[index];
    const previousMessage = messages[index - 1];
    const isLastMessage = index === messages.length - 1;
    const nextMessage =
      index < messages.length - 1 ? messages[index + 1] : null;

    if (currentMessage.senderId === userId) {
      if (previousMessage?.senderId === userId) {
        return nextMessage?.senderId === userId ? "you middle" : "you last";
      } else {
        return nextMessage?.senderId === userId ? "you first" : "you last";
      }
    } else {
      if (previousMessage?.senderId === currentMessage.senderId) {
        return nextMessage?.senderId === currentMessage.senderId
          ? "friend middle"
          : "friend last";
      } else {
        return nextMessage?.senderId === currentMessage.senderId
          ? "friend first"
          : "friend last";
      }
    }
  };
  const getMessageCurrent = (messages, index, userId) => {
    const currentMessage = messages[index];
    const previousMessage = messages[index - 1];
    const isLastMessage = index === messages.length - 1;
    const nextMessage =
      index < messages.length - 1 ? messages[index + 1] : null;

    if (currentMessage.senderId === userId) {
      if (previousMessage?.senderId === userId) {
        return nextMessage?.senderId === userId ? "you" : "you";
      } else {
        return nextMessage?.senderId === userId ? "you" : "you";
      }
    } else {
      if (previousMessage?.senderId === currentMessage.senderId) {
        return nextMessage?.senderId === currentMessage.senderId
          ? "friend"
          : "friend";
      } else {
        return nextMessage?.senderId === currentMessage.senderId
          ? "friend"
          : "friend";
      }
    }
  };

  const scrollToBottom = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    let unsubscribe; // Initialize a variable for the unsubscribe function

    const fetchProjectData = async () => {
      setLoading(true); // Ensure loading is set to true at the beginning

      try {
        const projectDocRef = doc(db, "projects", projectId);

        // Set up a real-time listener for the project document
        unsubscribe = onSnapshot(
          projectDocRef,
          (docSnapshot) => {
            if (docSnapshot.exists()) {
              setProjectData(docSnapshot.data());
            } else {
              setError("Project not found");
            }
          },
          (error) => {
            setError("Error fetching project details");
            console.error("Error fetching project details: ", error);
          }
        );
      } catch (error) {
        setError("Error fetching project details");
        console.error("Error fetching project details: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjectData();

    // Return the unsubscribe function for cleanup
    return () => {
      if (unsubscribe) {
        unsubscribe(); // Unsubscribe from the real-time listener when the component unmounts
      }
    };
  }, [projectId]);

  const handleScroll = () => {
    const list = listContainerRef.current;
    if (list) {
      const { scrollTop, scrollHeight, clientHeight } = list;
      const atBottom = scrollHeight - scrollTop === clientHeight;
      setShowScrollButton(!atBottom);
    }
  };
  if (loading) return <Loading />;
  return (
    <MDBContainer className="chat-messages">
      {/* Header */}
      <div
        className="chat-list-header"
        style={{ background: "var(--headerChat)" }}
      >
        <div
          className="chat-list-header-div"
          onClick={() => {
            navigate(`/profile/${chatPartnerId}`);
          }}
        >
          <Avatar
            name={chatPartnerName}
            backgroundColor="red.500"
            src="https://bit.ly/broken-link"
            className="avatar-img"
          ></Avatar>
          <div>
            <MDBTypography tag="h6">{chatPartnerName}</MDBTypography>
            <MDBTypography tag="p">
              {isOtherConnect ? "online" : "offline"}
            </MDBTypography>
          </div>
        </div>
      </div>

      {/* Body */}
      <div
        ref={listContainerRef}
        onScroll={handleScroll}
        style={{
          flex: "1",
          padding: "15px",
          overflowY: "auto",
        }}
        class="chat"
      >
        {loading ? (
          <Loading />
        ) : messages.length === 0 ? (
          <div
            style={{
              padding: "20px",
              width: "100%",
              height: "100%",
              color: "#6C757D",
              textAlign: "center",
              borderRadius: "8px",
              alignItems: "center",
            }}
          >
            No messages yet. Start the conversation!
          </div>
        ) : (
          <div class="chat-content clearfix">
            <span className="deleted-conversation-header">
              <p>Previous conversation was deleted</p>
            </span>
            {messages.map((msg, index) => {
              // Get the date of the current message
              const currentDate = new Date(
                msg.timestamp.toDate()
              ).toLocaleDateString("fr-FR", {
                day: "2-digit",
                month: "2-digit",
                year: "2-digit",
              });

              // Get the date of the previous message
              const previousDate =
                index > 0
                  ? new Date(
                      messages[index - 1].timestamp.toDate()
                    ).toLocaleDateString("fr-FR", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "2-digit",
                    })
                  : null;

              return (
                <React.Fragment key={index}>
                  {/* Check if it's the first message of the day */}
                  {index === 0 || currentDate !== previousDate ? (
                    <span className="date-conversation-split">
                      <p>{currentDate}</p>
                    </span>
                  ) : null}

                  <span
                    className={getMessageClass(messages, index, userData.uid)}
                  >
                    {msg.text ? (
                      msg.text
                    ) : (
                      <Skeleton
                        startColor="white.500"
                        endColor="black.500"
                        height="20px"
                      />
                    )}
                    <span className="time">
                      {new Date(msg.timestamp.toDate()).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                      <p>
                        {getMessageCurrent(messages, index, userData.uid) ===
                        "you" ? (
                          msg.read ? (
                            <IoCheckmarkDone color="blue" size="12px" />
                          ) : isOtherConnect ? (
                            <IoCheckmarkDone />
                          ) : (
                            <IoCheckmark />
                          )
                        ) : (
                          ""
                        )}
                      </p>
                    </span>
                  </span>
                </React.Fragment>
              );
            })}
            {otherUserTyping && <Typing isTyping={otherUserTyping} />}
            <div ref={messageEndRef} />
          </div>
        )}
        {showScrollButton && (
          <MDBBtn
            color="success"
            style={{
              position: "fixed",
              bottom: "70px",
              right: "30px",
              width: "40px",
              height: "40px",
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={scrollToBottom}
          >
            ↓
          </MDBBtn>
        )}
      </div>

      {/* Footer */}
      <InputGroup
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#1f1f1f4d",
          padding: "10px 20px",
          boxShadow: "0 0 1px 0.1px white",
        }}
        size="md"
      >
        {chatActive ? (
          <>
            {" "}
            <Input
              variant="unstyled"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Type a message"
              _placeholder={{ color: "gray.500" }}
              style={{ margin: "0", flex: 1, height: "50px", color: "white" }}
            />
            <InputRightElement
              width="4.5rem"
              display="flex"
              alignItems="center"
              height="100%"
              fontSize="small"
            >
              <IconButton
                onClick={handleSend}
                bg="#ecdfcc"
                aria-label="Send email"
                icon={<GrSend color="#2e2e2e" />}
              />
            </InputRightElement>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "2% 0",
              width: "100%",
            }}
          >
            {userData.role === "client" ? (
              <figcaption className="blockquote-footer">
                the project was canceled by the leader
              </figcaption>
            ) : (
              <figcaption className="blockquote-footer">
                the project was canceled by you
              </figcaption>
            )}
          </div>
        )}
      </InputGroup>
    </MDBContainer>
  );
}

export default Chat;
