import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBListGroup,
  MDBListGroupItem,
  MDBTypography,
} from "mdb-react-ui-kit";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  onSnapshot,
  query,
  limit,
} from "firebase/firestore";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
} from "mdb-react-ui-kit";
import "./notification.css";
import { db } from "../firebase";
import Notification from "./notification";
import useCurrentUser from "../currentUser/currentuser";
import NotificationSended from "./notificationSended";
import { Box, Divider } from "@chakra-ui/react";

export default function NotificationListModel({ optSmModal, toggleOpen }) {
  const [notifications, setNotifications] = useState([]);
  const [notificationsSended, setNotificationsSended] = useState([]);
  const Cuser = useCurrentUser();

  useEffect(() => {
    const fetchNotifications = () => {
      const notificationsCollection = collection(db, "notifications");

      // Real-time listener for notifications
      const unsubscribeNotifications = onSnapshot(
        notificationsCollection,
        async (notificationsSnapshot) => {
          const notificationsList = [];

          for (const notificationDoc of notificationsSnapshot.docs) {
            const notificationData = notificationDoc.data();
            const projectDoc = await getDoc(
              doc(db, "projects", notificationData.projectId)
            );

            if (projectDoc.exists()) {
              const projectData = projectDoc.data();
              const publicationDate = projectData.publicationDate
                ? new Date(
                    projectData.publicationDate.seconds * 1000
                  ).toLocaleDateString()
                : "No date";

              notificationsList.push({
                id: notificationDoc.id,
                userName: `${projectData.firstName} ${projectData.lastName}`,
                publicationDate,
                status: projectData.status,
                body: projectData.body,
                leader: projectData.leader,
              });
            }
          }

          setNotifications(notificationsList);
        }
      );

      return unsubscribeNotifications; // Cleanup function to unsubscribe when component unmounts
    };

    const fetchNotificationsSended = () => {
      let notificationsSendedCollection = collection(db, "notificationsSended");

      // Real-time listener for notificationsSended
      const unsubscribeNotificationsSended = onSnapshot(
        notificationsSendedCollection,
        (notificationsSnapshot) => {
          const notificationsList = [];

          notificationsSnapshot.forEach((notificationDoc) => {
            const notificationData = notificationDoc.data();

            if (notificationData.to === Cuser.uid && Cuser.role === "client") {
              notificationsList.push({
                projectId: notificationData.projectId,
                to: notificationData.to,
                checked: notificationData.checked,
              });
            }
          });

          setNotificationsSended(notificationsList);
        }
      );

      return unsubscribeNotificationsSended; // Cleanup function to unsubscribe when component unmounts
    };

    const unsubscribe1 = fetchNotifications();
    const unsubscribe2 = fetchNotificationsSended();

    return () => {
      unsubscribe1(); // Unsubscribe from notifications
      unsubscribe2(); // Unsubscribe from notificationsSended
    };
  }, [Cuser]);
  return (
    <MDBModal open={optSmModal} tabIndex="-1">
      <MDBModalDialog scrollable>
        <MDBModalContent className="notificationsContentModal">
          <MDBModalHeader className="notificationsContentHeaderModal">
            <MDBBtn
              color="light"
              className="btn-close"
              onClick={toggleOpen}
            ></MDBBtn>
          </MDBModalHeader>
          <Box className="notificationsDivider">
            <Divider orientation="horizontal" borderColor="transparent" />
          </Box>
          <MDBModalBody style={{ overflow: "auto", width: "100%" }}>
            <div className="notificationsSectionModal">
              <MDBListGroup className="list-group-not">
                {Cuser.role !== "client" && notificationsSended.length === 0
                  ? notifications.map((notification) => (
                      <MDBListGroupItem
                        key={notification.id}
                        className="d-flex justify-content-between align-items-center yesNotificationDiv listGroupItem"
                      >
                        {Cuser.role === "programmer" &&
                        notification.status === "waiting" ? null : (
                          <Notification
                            userName={notification.userName}
                            publicationDate={notification.publicationDate}
                            status={notification.status}
                            leader={notification.leader}
                            pId={notification.pId}
                          />
                        )}
                      </MDBListGroupItem>
                    ))
                  : notificationsSended.map((notificationSended) => (
                      <MDBListGroupItem
                        key={notificationSended.projectId}
                        className="d-flex justify-content-between align-items-center yesNotificationDiv listGroupItem"
                      >
                        <NotificationSended
                          to={notificationSended.to}
                          projectId={notificationSended.projectId}
                          checked={notificationSended.checked}
                        />
                      </MDBListGroupItem>
                    ))}

                {Cuser.role === "client" && notificationsSended.length === 0 ? (
                  <div className="noNotificationsDiv">
                    <MDBTypography>No Notifications Available</MDBTypography>
                    <figcaption className="blockquote-footer mb-0">
                      It seems there are no notifications to display at the
                      moment.
                    </figcaption>
                  </div>
                ) : Cuser.role !== "client" && notifications.length === 0 ? (
                  <div className="noNotificationsDiv">
                    <MDBTypography>No Notifications Available</MDBTypography>
                    <figcaption className="blockquote-footer mb-0">
                      It seems there are no notifications to display at the
                      moment.
                    </figcaption>
                  </div>
                ) : null}
              </MDBListGroup>
            </div>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
