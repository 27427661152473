import { Box, Text } from "lucide-react";
import "../profiles/Test.css";
import AvatarD, { OtherAvatarD } from "../contents/avatar";
import { MDBBtn } from "mdb-react-ui-kit";
import { db, deleteWorkingprogrammer } from "../firebase";
import { useEffect, useState } from "react";
import { Code } from "@chakra-ui/react";
import Loading from "../loading";
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

export const ProjectEmploymentDetails = ({ programmer }) => {
  const [timeR, setTimeR] = useState("");
  const handleCancelRequest = async () => {
    deleteWorkingprogrammer(programmer.workId, programmer.programmerId);
  };

  useEffect(() => {
    setTimeR(
      programmer.time &&
        new Date(
          programmer.time.seconds * 1000 + programmer.time.nanoseconds / 1000000
        ).toLocaleDateString("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        })
    );
  }, []);

  const handleApproveRequest = async () => {
    try {
      const projectRef = doc(db, "workingprojects", programmer.workId);
      const projectSnap = await getDoc(projectRef);

      if (!projectSnap.exists()) {
        console.error("Project not found!");
        return;
      }

      // Get existing project data
      const projectData = projectSnap.data();
      const formsArray = projectData.forms || []; // Ensure it's an array

      // Update only the specific object where type === "front-end"
      const updatedForms = formsArray.map((form) =>
        form.type === programmer.type
          ? { ...form, idprogrammer: programmer.programmerId, incharge: true }
          : form
      );

      // Update Firestore document
      await updateDoc(projectRef, {
        forms: updatedForms,
      });

      console.log("Project updated successfully!");

      // Query Firestore to find the correct request document
      const requestQuery = query(
        collection(db, "requestworkingprojects"),
        where("workId", "==", programmer.workId),
        where("programmerId", "==", programmer.programmerId)
      );

      const requestSnap = await getDocs(requestQuery);

      if (!requestSnap.empty) {
        const requestDoc = requestSnap.docs[0]; // Get the first matching document
        const requestRef = doc(db, "requestworkingprojects", requestDoc.id);

        await updateDoc(requestRef, {
          approved: true,
        });

        console.log("Request approved!");
      } else {
        console.error("Matching request not found in requestworkingprojects!");
      }
    } catch (error) {
      console.error("Error updating project:", error);
    }
  };
  

  if (programmer === undefined) <Loading />;

  return (
    <div className="col-xl-4 col-lg-6 mb-4">
      <div
        className="card"
        style={{
          background: "linear-gradient(135deg, #171717, #000000)",
          color: "var(--primary-color-h1)",
        }}
      >
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-start">
            <div className="d-flex align-items-center">
              <OtherAvatarD userId={programmer.programmerId} />
              <div className="ms-3">
                <p
                  className="fw-bold mb-1"
                  style={{
                    fontFamily: "Quicksand",
                    fontWeight: 300,
                    fontSize: "medium",
                  }}
                >
                  {programmer.fullname}
                </p>
                <p
                  className="text-muted mb-0"
                  style={{
                    fontFamily: "Quicksand",
                    fontWeight: 200,
                    fontSize: "small",
                  }}
                >
                  {timeR}
                </p>
              </div>
            </div>
            <Code children={programmer.request} colorScheme="white" />
          </div>
        </div>
        <div className="card-footer border-0 bg-body-tertiary p-2 d-flex justify-content-around">
          <MDBBtn
            type="button"
            style={{ flex: 1 }}
            color="success"
            className="me-1"
            outline
            onClick={handleApproveRequest}
          >
            Approve
          </MDBBtn>
          <MDBBtn
            type="button"
            color="danger"
            className="me-1"
            outline
            style={{ flex: 1 }}
            onClick={handleCancelRequest}
          >
            Cancel
          </MDBBtn>
        </div>
      </div>
    </div>
  );
};
