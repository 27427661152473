import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

const useNavigateUserRole = (userId) => {
  const navigate = useNavigate();

  useEffect(() => {
    const navigateToUserRole = async () => {
      if (!userId) return;

      try {
        const docRef = doc(db, "users", userId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userRole = docSnap.data().role || "client";

          const roleRoutes = {
            master: "/master",
            programmer: "/programmer",
            groupleader: "/groupleader",
            support: "/support",
            client: "/client",
          };

          navigate(roleRoutes[userRole] || "/notExist");
        } else {
          console.warn(`User document (${userId}) not found.`);
          navigate("/notExist");
        }
      } catch (error) {
        console.error("Error fetching user role:", error);
        navigate("/error");
      }
    };

    navigateToUserRole();
  }, [userId, navigate]);
};

export default useNavigateUserRole;
