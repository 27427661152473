import Header from "../contents/header";
import useCurrentUser from "../currentUser/currentuser";
import ProjectList from "../projectList/projectList";
import NotificationList from "../notifications/notificationList";
import { MDBContainer } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase"; // Import your Firestore and auth instances
import { doc, getDoc } from "firebase/firestore";
import Logout from "../Logout";
import Chat from "../projectList/chat";
import Loading from "../loading";
import ChatList from "../projectList/chatlist";
import AvatarD from "../contents/avatar";
import ShapeSection from "./shapeSection";
import ProjectsEmploymentList from "../employment/projectemploymentlist";
function GroupLeaderDashboard() {
  const userData = useCurrentUser();
  const [activePage, setActivePage] = useState("Home");
  const [currentProject, setCurrentProject] = useState(null);

  useEffect(() => {
    const fetchProject = async () => {
      const projectId = "some-project-id";
      const docRef = doc(db, "projects", projectId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setCurrentProject(docSnap.data());
      } else {
        console.log("No such project!");
      }
    };

    if (activePage === "Chats" && currentProject) {
      fetchProject();
    }
  }, [activePage, currentProject]);

  const renderContent = () => {
    switch (activePage) {
      case "Home":
        return (
          <ShapeSection
            title={"Dashboard"}
            compenent={<ProjectList />}
            userData={userData}
          />
        );
        case "Employment":
        return (
          <ShapeSection
            title={"employment"}
            compenent={<ProjectsEmploymentList />}
            userData={userData}
          />
        );
      case "Chats":
        return (
          <ShapeSection
            title={"Chat"}
            compenent={<ChatList />}
            userData={userData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="all-things-here">
      <Header
        children={activePage}
        user={userData}
        onItemSelected={setActivePage}
      />
      {renderContent()}
      <NotificationList />
    </div>
  );
}

export default GroupLeaderDashboard;
