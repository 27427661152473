import { MDBContainer } from "mdb-react-ui-kit";
import React from "react";
import AvatarD from "../contents/avatar";

export default function ShapeSection({ title, compenent, userData }) {
  return (
    <div className="DashboardDivMaster">
      <div
        style={{
          display: "flex",
          gap: "15px",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <MDBContainer
          style={{
            display: "flex",
            height: "100%",
            paddingTop: "5px",
            gap: "10px",
            flexDirection: "column",
          }}
        >
          <div className="projectSection">{compenent}</div>
        </MDBContainer>
      </div>
    </div>
  );
}
