import React, { useState, useRef, useEffect } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBInput,
  MDBPopover,
  MDBPopoverBody,
  MDBTextArea,
  MDBValidation,
  MDBValidationItem,
} from "mdb-react-ui-kit";
import "../profiles/Test.css";
import { BanIcon, EditIcon, HeartIcon, TrashIcon } from "lucide-react";
import useCurrentUser from "../currentUser/currentuser";
import { createNewChat, db } from "../firebase";
import {
  updateDoc,
  doc,
  setDoc,
  getDoc,
  where,
  getDocs,
  writeBatch,
  collection,
  query,
  arrayRemove,
  arrayUnion,
  onSnapshot,
} from "firebase/firestore";
import { IoEllipsisVerticalSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import "./project.css";
import Test from "../profiles/Test";
import { Skeleton, SkeletonText } from "@chakra-ui/react";

const formatTextWithLineBreaks = (text) => {
  return text.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
};

function Project({
  clientN,
  dateP,
  stat,
  leader,
  puid,
  body,
  subject,
  id,
  minAmount = "*",
  maxAmount = "*",
  projectNotif = false,
  handleOpen,
}) {
  const [hovered, setHovered] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [isTruncatable, setIsTruncatable] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newBody, setNewBody] = useState(body);
  const [newSubject, setNewSubject] = useState(subject);
  const [newMinAmount, setNewMinAmount] = useState(minAmount);
  const [newMaxAmount, setNewMaxAmount] = useState(maxAmount);
  const [hasReported, setHasReported] = useState(false);
  const textRef = useRef(null);
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [loading, setLoading] = useState(true);

  const userData = useCurrentUser();

  const [likes, setLikes] = useState(0);
  const [userLiked, setUserLiked] = useState(false);

  useEffect(() => {
    const projectRef = doc(db, "projects", id);

    const unsubscribe = onSnapshot(
      projectRef,
      (projectSnapshot) => {
        if (projectSnapshot.exists()) {
          const projectData = projectSnapshot.data();
          setLikes(projectData.likes?.length || 0);
          setUserLiked(projectData.likes?.includes(userData.uid));
        }
      },
      (error) => {
        console.error("Error fetching real-time likes:", error);
      }
    );

    // Cleanup the listener on component unmount
    return () => unsubscribe();
  }, [id, userData]);

  const handleLikeClick = () => {
    handleLikes(id, userData.uid, setLikes).then(() => {
      setUserLiked(!userLiked);
    });
  };

  useEffect(() => {
    if (textRef.current) {
      const lineHeight = parseInt(
        window.getComputedStyle(textRef.current).lineHeight
      );
      const maxHeight = lineHeight * 3; // For 3 lines
      if (textRef.current.scrollHeight > maxHeight) {
        setIsTruncatable(true);
      } else {
        setIsTruncatable(false);
      }
    }
  }, []);

  useEffect(() => {
    const checkIfReported = async () => {
      try {
        const reportId = `${id}_${userData.uid}`;
        const reportRef = doc(db, "reports", reportId);
        const reportDoc = await getDoc(reportRef);
        if (reportDoc.exists()) {
          setHasReported(true);
        }
      } catch (error) {
        console.error("Error checking report status: ", error);
      }
    };

    checkIfReported();
    setLoading(false);
  }, [id, userData.uid]);

  const handleHover = () => {
    setHovered(!hovered);
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      const projectRef = doc(db, "projects", id);
      await updateDoc(projectRef, {
        body: newBody,
        subject: newSubject,
        minAmount: newMinAmount,
        maxAmount: newMaxAmount,
      });
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const handleLikes = async (projectId, userId, setLikes) => {
    try {
      const projectRef = doc(db, "projects", projectId);
      const projectSnapshot = await getDoc(projectRef);

      if (projectSnapshot.exists()) {
        const projectData = projectSnapshot.data();
        const currentLikes = projectData.likes || [];

        if (currentLikes.includes(userId)) {
          // Unlike: Remove the user's UID from the likes array
          await updateDoc(projectRef, {
            likes: arrayRemove(userId),
          });
        } else {
          // Like: Add the user's UID to the likes array
          await updateDoc(projectRef, {
            likes: arrayUnion(userId),
          });
        }

        // Fetch the updated data and update the UI
        const updatedProjectSnapshot = await getDoc(projectRef);
        const updatedProjectData = updatedProjectSnapshot.data();
        setLikes(updatedProjectData.likes?.length || 0);
      }
    } catch (error) {
      console.error("Error handling likes:", error);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleDelete = async () => {
    const batch = writeBatch(db);

    try {
      // 1. Query all chat documents that contain the projectId
      const chatsRef = collection(db, "chats");
      const chatsQuery = query(chatsRef, where("projectId", "==", id));
      const chatDocs = await getDocs(chatsQuery);

      // 2. Collect all chat IDs and leader IDs
      const chatIds = chatDocs.docs.map((doc) => doc.id);
      const leaderIds = new Set(
        chatDocs.docs.map((doc) => doc.data().leaderId)
      );

      // 3. Query and delete all messages related to the collected chat IDs
      for (const chatId of chatIds) {
        const messagesRef = collection(db, "messages");
        const messagesQuery = query(messagesRef, where("chatId", "==", chatId));
        const messageDocs = await getDocs(messagesQuery);

        messageDocs.forEach((messageDoc) => {
          batch.delete(messageDoc.ref);
        });

        // Delete the chat document
        batch.delete(doc(db, "chats", chatId));
      }

      // 4. Update leader status in the 'users' collection
      for (const leaderId of leaderIds) {
        const leaderRef = doc(db, "users", leaderId);
        batch.update(leaderRef, { working: false, projectWorkingFor: "" });
      }

      // 5. Query and delete notifications related to the projectId
      const notificationsRef = collection(db, "notifications");
      const notificationsQuery = query(
        notificationsRef,
        where("projectId", "==", id)
      );
      const notificationDocs = await getDocs(notificationsQuery);

      notificationDocs.forEach((notificationDoc) => {
        batch.delete(notificationDoc.ref);
      });

      // 6. Delete the project document
      batch.delete(doc(db, "projects", id));

      // 7. Decrement the user's MProjects count
      const userRef = doc(db, "users", puid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const currentMProjects = userSnap.data().MProjects || 0;
        const newMProjects = Math.max(0, currentMProjects - 1);
        batch.update(userRef, { MProjects: newMProjects });
      }

      // 8. Commit the batch operation
      await batch.commit();
      console.log(
        "Project, related data, notifications, and MProjects count successfully updated!"
      );
    } catch (error) {
      console.error("Error deleting project and related data: ", error);
    }
  };

  const handleReport = async () => {
    try {
      const reportId = `${id}_${userData.uid}`;
      await setDoc(doc(db, "reports", reportId), {
        publicationId: id,
        reportedById: userData.uid,
        publicationUserId: puid,
        timestamp: new Date(),
      });
      setHasReported(true);
      console.log("Report successfully submitted!");
    } catch (error) {
      console.error("Error submitting report: ", error);
    }
  };

  const handleApprove = async () => {
    try {
      const projectRef = doc(db, "projects", id);
      const userRef = doc(db, "users", userData.uid);

      // Update project with leader details and status
      await updateDoc(projectRef, {
        leader: `${userData.firstName} ${userData.lastName}`,
        leaderId: userData.uid,
        status: "approved",
        work: "on it",
      });

      // Update the leader's status to indicate they are working on a project
      await updateDoc(userRef, {
        working: true,
        projectWorkingFor: id,
      });

      const userRefC = doc(db, "users", puid);
      // Fetch the current value of AProjects (approved projects count)
      const userSnap = await getDoc(userRefC);

      if (userSnap.exists()) {
        const currentAProjects = userSnap.data().AProjects || 0;
        const newAProjects = currentAProjects + 1;

        // Update the user's AProjects count
        await updateDoc(userRefC, {
          AProjects: newAProjects,
        });
      }

      // Create chat between client and leader
      createNewChat(puid, userData.uid, id);
    } catch (error) {
      console.error("Error approving project and updating user data: ", error);
    }
  };

  if (loading) {
    return (
      <div className="post-card creative">
        <div className="post-header">
          <div className="post-client">
            <span className="post-client-name"></span>
            <span className="post-date">
              <SkeletonText
                mt="2"
                noOfLines={2}
                spacing="2"
                width="50px"
                skeletonHeight="2"
              />
            </span>
          </div>
          <div className="post-actions"></div>
        </div>

        <div className="post-content">
          <SkeletonText mt="1" noOfLines={1} width="100%" skeletonHeight="50" />
        </div>

        <div className="post-footer">
          <div className="post-amount">
            <SkeletonText mt="1" noOfLines={1} width="20%" skeletonHeight="2" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="post-card creative">
        <div className="post-header">
          <div className="post-client">
            <span
              className="post-client-name"
              onClick={() => {
                navigate(`/profile/${puid}`);
              }}
            >
              {clientN}
            </span>
            <span className="post-date">
              {dateP &&
                new Date(
                  dateP.seconds * 1000 + dateP.nanoseconds / 1000000
                ).toLocaleDateString("en-US", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                })}
            </span>
          </div>
          <div style={{ display: "flex", gap: "1%" }}>
            {userData.role !== "groupleader" &&
            userData.role !== "programmer" ? (
              <>
                {userData.uid === puid || userData.role === "master" ? (
                  <div style={{ display: "flex", gap: "5%" }}>
                    {userData.uid === puid && stat === "waiting" ? (
                      <EditIcon
                        onClick={handleEdit}
                        color="orange"
                        style={{ cursor: "pointer" }}
                      />
                    ) : null}
                    <TrashIcon
                      onClick={handleDelete}
                      color="red"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                ) : userData.role === "client" ? (
                  <BanIcon
                    onClick={handleReport}
                    disabled={hasReported}
                    color={hasReported ? "grey" : "red"}
                    style={{
                      cursor: `${hasReported ? "not-allowed" : "pointer"}`,
                    }}
                  />
                ) : null}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="post-content">
          {isEditing ? (
            <>
              <MDBValidation className="row g-3" isValidated>
                <MDBValidationItem
                  className="mb-3 pb-1"
                  feedback="Please enter a message in the subject."
                  invalid
                >
                  <MDBInput
                    label="subject"
                    size="sm"
                    value={newSubject}
                    onChange={(e) => setNewSubject(e.target.value)}
                    className="projectusers-subject"
                    id="validationsubject"
                    placeholder="Required example subject"
                    required
                  />
                </MDBValidationItem>
                <MDBValidationItem
                  className="mb-3 pb-1"
                  feedback="Please enter a message in the textarea."
                  invalid
                >
                  <MDBTextArea
                    label="Textarea"
                    size="sm"
                    value={newBody}
                    onChange={(e) => setNewBody(e.target.value)}
                    className="projectusers-textarea"
                    id="validationTextarea"
                    placeholder="Required example textarea"
                    required
                  />
                </MDBValidationItem>
                <MDBValidationItem
                  className="col-md-6"
                  feedback="The minimum amount should be lower than the maximum amount."
                  invalid
                >
                  <MDBInput
                    type="number"
                    color="white"
                    size="sm"
                    autoComplete={false}
                    value={newMinAmount}
                    name="Min Amount"
                    id="validationCustom03"
                    className="projectusers-input"
                    required
                    max={newMaxAmount}
                    onChange={(e) => setNewMinAmount(e.target.value)}
                    label="Min Amount"
                  />
                </MDBValidationItem>
                <MDBValidationItem
                  className="col-md-6"
                  feedback="The maximum amount should be higher than the minimum amount."
                  invalid
                >
                  <MDBInput
                    type="number"
                    color="white"
                    size="sm"
                    value={newMaxAmount}
                    autoComplete={false}
                    name="Max Amount"
                    id="validationCustom03"
                    className="projectusers-input"
                    required
                    min={newMinAmount}
                    onChange={(e) => setNewMaxAmount(e.target.value)}
                    label="Max Amount"
                  />
                </MDBValidationItem>
              </MDBValidation>
              <div className="projectusers-btn-group">
                <MDBBtn color="success" onClick={handleSave}>
                  Save
                </MDBBtn>
                <MDBBtn outline color="danger" onClick={handleCancel}>
                  Cancel
                </MDBBtn>
              </div>
            </>
          ) : (
            <>
              <span className="projectusers-subject-text">{subject}</span>
              <div
                ref={textRef}
                className={
                  expanded
                    ? `projectusers-body-text expanded`
                    : `projectusers-body-text`
                }
              >
                {formatTextWithLineBreaks(body)}
              </div>
            </>
          )}
          {isTruncatable && !isEditing && (
            <span className="projectusers-show-more" onClick={toggleExpanded}>
              {expanded ? "Show Less" : "Show More"}
            </span>
          )}
        </div>

        <div className="post-footer">
          <div className="post-amount">
            {`Budget : ${minAmount}$ - ${maxAmount}$`}{" "}
            <span
              style={{
                display: "flex",
                gap: "10%",
                alignItems: "center",
                textWrap: "nowrap",
              }}
            >
              <HeartIcon
                color={"pink"}
                fill={userLiked ? "pink" : "none"}
                cursor="pointer"
                onClick={handleLikeClick}
              />
              {likes} likes
            </span>
          </div>
          <MDBBtn
            color={stat === "approved" ? "success" : "warning"}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            disabled={
              (userData.role === "groupleader" &&
                userData.working === true &&
                stat === "waiting") ||
              (userData.role === "client" && stat === "waiting") ||
              (userData.role === "programmer" && stat === "waiting")
                ? true
                : false
            }
            onClick={() => {
              if (
                userData.role === "groupleader" &&
                stat === "waiting" &&
                userData.working === false
              ) {
                handleApprove();
              }
            }}
            style={{
              transition: "all 0.3s ease-in-out",
            }}
          >
            <span
              style={{
                opacity: isHovered ? 0 : 1,
                transition: "opacity 0.3s ease-in-out",
                fontSize: "x-small",
                textWrap: "nowrap",
              }}
            >
              {stat === "approved" ? "Approved" : "Waiting"}
            </span>
            <span
              style={{
                opacity: isHovered ? 1 : 0,
                transition: "opacity 0.3s ease-in-out",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                fontSize: "x-small",
                textWrap: "nowrap",
              }}
            >
              {stat === "approved"
                ? leader
                : userData.role !== "groupleader" && "waiting"}
              {stat === "waiting" && userData.role === "groupleader"
                ? "Approve"
                : ""}
            </span>
          </MDBBtn>
          {projectNotif && (
            <MDBBtn
              type="button"
              color="danger"
              className="me-1"
              outline
              onClick={handleOpen}
            >
              Close
            </MDBBtn>
          )}
        </div>
      </div>
    </>
  );
}

export default Project;
