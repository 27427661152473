// src/firebase.js
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  sendEmailVerification,
  onAuthStateChanged,
} from "firebase/auth";
import {
  TwitterAuthProvider,
  GithubAuthProvider,
  OAuthProvider,
} from "firebase/auth";
import {
  getFirestore,
  setDoc,
  doc,
  getDoc,
  collection,
  query,
  getDocs,
  updateDoc,
  increment,
  arrayUnion,
  addDoc,
  Timestamp,
  onSnapshot,
  limit,
  where,
  deleteDoc,
} from "firebase/firestore";

// When creating a new chat
import CryptoJS from "crypto-js";
import useCurrentUser from "./currentUser/currentuser";

// const firebaseConfig = {
//   apiKey: "AIzaSyDPVPdYLDJZruFYbocbV9nI-uvdcfd6_rg",
//   authDomain: "meetingmb-56371.firebaseapp.com",
//   projectId: "meetingmb-56371",
//   storageBucket: "meetingmb-56371.appspot.com",
//   messagingSenderId: "1047761498906",
//   appId: "1:1047761498906:web:0b68db8f7d366cbd0532a3",
// };

const firebaseConfig = {
  apiKey: "AIzaSyCu1qKON0HBna4V0j6JYmCWv1VnGP2O5cI",
  authDomain: "web0weave.firebaseapp.com",
  projectId: "web0weave",
  storageBucket: "web0weave.firebasestorage.app",
  messagingSenderId: "926512926395",
  appId: "1:926512926395:web:0a1726a64f3c47c5fea39e",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

// Initialize social media providers
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const twitterProvider = new TwitterAuthProvider();
const githubProvider = new GithubAuthProvider();

// Correct initialization for Yahoo provider (using the right provider ID)
const yahooProvider = new OAuthProvider("yahoo.com");

// Function to update visitor count
export const updateVisitorCountA = async () => {
  const docRef = doc(db, "stats", "visitorCount");
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    // If the document exists, increment the visitor count
    await updateDoc(docRef, {
      count: increment(1),
    });
  } else {
    // If the document doesn't exist, create it with an initial count of 1
    await setDoc(docRef, { count: 1 });
  }
};
export const updateVisitorCount = async () => {
  const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
  const docRef = doc(db, "dailyVisitors", today);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    // If the document for today exists, increment the visitor count
    await updateDoc(docRef, {
      count: increment(1),
    });
  } else {
    // If the document for today doesn't exist, create it with an initial count of 1
    await setDoc(docRef, { count: 1 });
  }
};

// Generate a random AES key
export const createNewChat = async (clientId, leaderId, Pid) => {
  const encryptionKey = CryptoJS.lib.WordArray.random(16).toString(); // 128-bit key

  // Use clientId_leaderId as the document ID within the "chats" collection
  const chatDocRef = doc(db, "chats", `${clientId}_${leaderId}`);

  await setDoc(chatDocRef, {
    clientId: clientId,
    leaderId: leaderId,
    projectId: Pid,
    encryptionKey,
    active: true,
    timestamp: Timestamp.fromDate(new Date()),
  });
};

export const getUserById = async (userId) => {
  try {
    const userDocRef = doc(db, "users", userId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      return userDoc.data();
    } else {
      console.error("No such user!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user details: ", error);
    return null;
  }
};
export const getProjectById = async (projectId) => {
  try {
    const projectDocRef = doc(db, "projects", projectId);
    const projectDoc = await getDoc(projectDocRef);
    if (projectDoc.exists()) {
      return projectDoc.data();
    } else {
      console.error("No such project!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching project details: ", error);
    return null;
  }
};
// Real-time user data listener
export const getUserByIdRealTime = (userId, callback) => {
  try {
    const userDocRef = doc(db, "users", userId);
    return onSnapshot(
      userDocRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          callback(docSnapshot.data());
        } else {
          console.error("No such user!");
          callback(null);
        }
      },
      (error) => {
        console.error("Error fetching user details: ", error);
        callback(null);
      }
    );
  } catch (error) {
    console.error("Error setting real-time listener: ", error);
  }
};

// Real-time project data listener
export const getProjectByIdRealTime = (projectId, callback) => {
  try {
    const projectDocRef = doc(db, "projects", projectId);
    return onSnapshot(
      projectDocRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          callback(docSnapshot.data());
        } else {
          console.error("No such project!");
          callback(null);
        }
      },
      (error) => {
        console.error("Error fetching project details: ", error);
        callback(null);
      }
    );
  } catch (error) {
    console.error("Error setting real-time listener: ", error);
  }
};

export const setProjectById = async (projectId, fixAmount) => {
  try {
    const projectDocRef = doc(db, "projects", projectId);
    await updateDoc(projectDocRef, {
      fixAmount: fixAmount,
    });
  } catch (error) {
    console.error("Error fetching project details: ", error);
    return null;
  }
};
// Function to get visitor count
export const getVisitorCountA = async () => {
  const docRef = doc(db, "stats", "visitorCount");
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data().count;
  } else {
    return 0; // If the document doesn't exist, return 0
  }
};
export const getVisitorCounts = async (timeFrame) => {
  const querySnapshot = await getDocs(collection(db, "dailyVisitors"));
  const visitorData = [];

  querySnapshot.forEach((doc) => {
    visitorData.push({
      date: doc.id,
      count: doc.data().count,
    });
  });

  // Sort the data by date
  visitorData.sort((a, b) => new Date(a.date) - new Date(b.date));

  if (timeFrame === "Monthly") {
    // Group data by month
    const monthlyData = {};
    visitorData.forEach((entry) => {
      const month = entry.date.slice(0, 7); // Get "YYYY-MM"
      if (!monthlyData[month]) {
        monthlyData[month] = 0;
      }
      monthlyData[month] += entry.count;
    });
    return Object.entries(monthlyData).map(([date, count]) => ({
      date,
      count,
    }));
  }

  if (timeFrame === "Yearly") {
    // Group data by year
    const yearlyData = {};
    visitorData.forEach((entry) => {
      const year = entry.date.slice(0, 4); // Get "YYYY"
      if (!yearlyData[year]) {
        yearlyData[year] = 0;
      }
      yearlyData[year] += entry.count;
    });
    return Object.entries(yearlyData).map(([date, count]) => ({ date, count }));
  }

  // Default: Daily data
  return visitorData;
};

export const getUserCountsByRole = async () => {
  const q = query(collection(db, "users"));
  const querySnapshot = await getDocs(q);

  const roleCounts = {
    client: 0,
    programmer: 0,
    groupleader: 0,
    master: 0,
  };

  querySnapshot.forEach((doc) => {
    const data = doc.data();
    const role = data.role;
    if (roleCounts[role] !== undefined) {
      roleCounts[role] += 1;
    }
  });

  return roleCounts;
};

const generateUniqueId = () => `conn-${Date.now()}`; // Generates a unique ID based on timestamp

export const updateUserConnection = async (userId, type) => {
  const docRef = doc(db, "users", userId);

  // Create a new entry with a unique ID and timestamp
  const newEntry = {
    id: generateUniqueId(),
    type: type,
    timestamp: new Date(), // Use local timestamp
  };

  // Overwrite the connectionHistory field with the new entry
  await updateDoc(docRef, {
    connectionHistory: [newEntry], // Replacing the entire array with the new entry
  });
};

export const getWorkingProjectsSnapshot = (onSuccess, onError) => {
  try {
    const projectCollectionRef = collection(db, "workingprojects");

    const unsubscribe = onSnapshot(
      projectCollectionRef,
      (querySnapshot) => {
        const projects = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        onSuccess(projects); // Pass the fetched projects to the success callback
      },
      (error) => {
        console.error("Error fetching project details: ", error);
        if (onError) onError(error); // Pass error to the error callback if provided
      }
    );

    return unsubscribe; // Return the unsubscribe function for cleanup
  } catch (error) {
    console.error("Error setting up snapshot listener: ", error);
    return () => {}; // Return a no-op function to avoid errors
  }
};

export const boolenWorkingProject = async (
  lId,
  cId,
  pId,
  setBoolWorkingProject
) => {
  try {
    const projectCollectionRef = collection(db, "workingprojects");
    const q = query(
      projectCollectionRef,
      where("leaderId", "==", lId),
      where("clientId", "==", cId),
      where("projectId", "==", pId)
    );
    // Listen for real-time updates to the collection
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      // If there's any matching document, set checkType to true, otherwise false
      if (querySnapshot.size > 0) {
        setBoolWorkingProject(true);
      } else {
        setBoolWorkingProject(false);
      }
    });

    // Return the unsubscribe function to stop listening to the updates
    return unsubscribe;
  } catch (e) {
    console.log(e);
  }
};

export const checkApprovementProgrammer = (
  prId,
  wId,
  pId,
  setCheckApprovement
) => {
  try {
    const projectCollectionRef = collection(db, "requestworkingprojects");
    const q = query(
      projectCollectionRef,
      where("programmerId", "==", prId),
      where("projectId", "==", pId),
      where("workId", "==", wId)
    );

    return onSnapshot(q, (querySnapshot) => {
      if (querySnapshot.empty) {
        console.log("No matching documents. Approval set to false.");
        setCheckApprovement(false); // Set explicitly to false when data is deleted
        return;
      }

      let approved = false;
      querySnapshot.forEach((doc) => {
        console.log(doc.data().approved);
        if (doc.data().approved === true) {
          approved = true;
        }
      });

      setCheckApprovement(approved);
    });
  } catch (e) {
    console.log("Error in checkApprovementProgrammer:", e);
    setCheckApprovement(false); // Prevent indefinite loading
    return null;
  }
};

export const checkWorkingProjects = (wpId, programmerId, setCheckType) => {
  try {
    const projectCollectionRef = collection(db, "requestworkingprojects");

    // Query to find documents where wpId is workId and programmerId is the programmer's ID
    const q = query(
      projectCollectionRef,
      where("workId", "==", wpId),
      where("programmerId", "==", programmerId)
    );

    // Listen for real-time updates to the collection
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (!querySnapshot.empty) {
        setCheckType(
          querySnapshot.docs.map((doc) => ({
            type: doc.data().type, // Corrected: accessing type from doc data
          }))
        );
      } else {
        setCheckType([]); // Return an empty array if no matching documents
      }
    });

    // Return the unsubscribe function to stop listening to the updates
    return unsubscribe;
  } catch (error) {
    console.log("Error checking working projects:", error);
    setCheckType([]); // Set empty array if there's an error
  }
};

export const deleteWorkingprogrammer = async (wpId, programmerId) => {
  try {
    // Create a query to find the document with wpId and programmerId
    const q = query(
      collection(db, "requestworkingprojects"),
      where("workId", "==", wpId),
      where("programmerId", "==", programmerId)
    );

    // Get the query snapshot
    const querySnapshot = await getDocs(q);

    // Check if any documents match the query
    querySnapshot.forEach(async (docSnap) => {
      // Delete the document if it exists
      await deleteDoc(docSnap.ref);
    });

    if (querySnapshot.empty) {
      console.log("No matching documents found.");
    }
  } catch (error) {
    console.error("Error canceling the request: ", error);
  }
};

export const getUserConnectionStatus = async (userId) => {
  try {
    const docRef = doc(db, "users", userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const userData = docSnap.data();
      const connectionHistory = userData.connectionHistory || [];
      const latestEntry = connectionHistory[connectionHistory.length - 1];

      if (latestEntry) {
        return latestEntry.type === "connect" ? "online" : "offline";
      }
    }
    return "offline";
  } catch (error) {
    console.error("Error fetching user connection status: ", error);
    return "offline";
  }
};
// Track connection and disconnection
export const trackUserConnection = () => {
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      // User has logged in
      await updateUserConnection(user.uid, "connect");
    } else {
      // User has logged out
      // Assuming you have a way to get the current user's UID
      const currentUser = auth.currentUser;
      if (currentUser) {
        await updateUserConnection(currentUser.uid, "disconnect");
      }
    }
  });
};

export const getAllConnectedUsers = ({ onUpdate, limitCall }) => {
  let q = query(collection(db, "users"), where("role", "!=", "master"));
  if (limitCall) {
    q = query(q, limit(limitCall));
  }

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const connectedUsers = [];

    querySnapshot.forEach((doc) => {
      const userData = doc.data();
      const connectionHistory = userData.connectionHistory || [];
      const latestEntry = connectionHistory[connectionHistory.length - 1];

      if (latestEntry && latestEntry.type === "connect") {
        if (doc.id !== auth.currentUser.uid) {
          connectedUsers.push({
            id: doc.id,
            ...userData,
          });
        }
      }
    });

    if (onUpdate) onUpdate(connectedUsers);
  });

  return unsubscribe;
};

export const getAllUsers = async () => {
  const q = query(collection(db, "users"));
  const querySnapshot = await getDocs(q);

  const allUsers = [];

  querySnapshot.forEach((doc) => {
    const userData = doc.data();
    if (doc.id !== auth.currentUser.uid) {
      allUsers.push({
        id: doc.id,
        ...userData,
      });
    }
  });

  return allUsers;
};
export const getConnectedUserById = async (userId) => {
  const userDocRef = doc(db, "users", userId);
  const userDocSnap = await getDoc(userDocRef);

  if (userDocSnap.exists()) {
    const userData = userDocSnap.data();
    const connectionHistory = userData.connectionHistory || [];

    const latestEntry = connectionHistory[connectionHistory.length - 1];

    if (latestEntry && latestEntry.type === "connect") {
      return true;
    } else {
      return false;
    }
  }

  return null;
};

export const handleSocialSignIn = async (provider, navigate, rememberMe) => {
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    let firstName = "";
    let lastName = "";
    let birthday = "";

    if (
      provider === googleProvider ||
      provider === facebookProvider ||
      provider === twitterProvider
    ) {
      const displayName = user.displayName
        ? user.displayName.split(" ")
        : ["", ""];
      firstName = displayName[0] || "";
      lastName = displayName[1] || "";
    } else if (provider === githubProvider) {
      firstName = user.displayName || user.email.split("@")[0];
      lastName = "";
    } else if (provider === yahooProvider) {
      const displayName = user.displayName
        ? user.displayName.split(" ")
        : ["", ""];
      firstName = displayName[0] || "";
      lastName = displayName[1] || "";
    }

    if (rememberMe) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      sessionStorage.setItem("user", JSON.stringify(user));
    }

    const docRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const userData = docSnap.data();
      const userRole = userData.role || "client";

      switch (userRole) {
        case "master":
          navigate("/master");
          break;
        case "programmer":
          navigate("/programmer");
          break;
        case "groupleader":
          navigate("/groupleader");
          break;
        default:
          navigate("/client");
          break;
      }
    } else {
      await setDoc(doc(db, "users", user.uid), {
        firstName,
        lastName,
        birthday,
        email: user.email,
        role: "client",
        MProjects: 0,
        AProjects: 0,
        showEmail: true,
        showPhone: true,
      });

      navigate("/client");
    }
  } catch (error) {
    console.error("Error with social login:", error.message);
  }
};

export {
  auth,
  db,
  googleProvider,
  facebookProvider,
  twitterProvider,
  githubProvider,
  yahooProvider,
};
