import {
  MDBBtn,
  MDBCol,
  MDBListGroupItem,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBTooltip,
} from "mdb-react-ui-kit";
import React, { useEffect, useRef, useState } from "react";
import "./nLeader.css";
import { useNavigate } from "react-router-dom";
import { Box, ScanEyeIcon } from "lucide-react";
import { Avatar, AvatarGroup, Divider, Stack } from "@chakra-ui/react";
import {
  db,
  deleteWorkingprogrammer,
  getProjectById,
  getUserById,
  getWorkingProjects,
  getWorkingProjectsSnapshot,
} from "../firebase";
import Project from "../projectList/project";
import Loading from "../loading";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

export default function Notification({
  userName,
  publicationDate,
  status,
  leader,
  pId,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);

  const handleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (open) {
      inputRef.current?.focus();
    }
  }, [open]);

  return (
    <MDBCol xl={12} lg={6} className="notificationLeaderCol">
      <div className="notification-card">
        <div className="notification-header">
          <span className="notification-name">{userName}</span>
          <span style={{ cursor: "pointer" }}>
            <ScanEyeIcon onClick={() => handleOpen()} />
            {open && (
              <NotificationPub open={open} handleOpen={handleOpen} pId={pId} />
            )}
          </span>
        </div>
        <div className="notification-footer">
          <span className="notification-timing">{publicationDate}</span>
          <MDBBtn
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            outline
            disabled
            style={{ cursor: "pointer" }}
            color={status === "approved" ? "success" : "warning"}
            size="sm"
            type="button"
            className="notificationLeaderBtn"
          >
            <span
              style={{
                opacity: isHovered && leader ? 0 : 1,
                transition: "opacity 0.3s ease-in-out",
                fontSize: "x-small",
                textWrap: "nowrap",
              }}
            >
              {status}
            </span>
            <span
              style={{
                opacity: isHovered && leader ? 1 : 0,
                transition: "opacity 0.3s ease-in-out",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                fontSize: "x-small",
                textWrap: "nowrap",
              }}
            >
              {status === "approved" && leader}
            </span>
          </MDBBtn>
        </div>
      </div>
    </MDBCol>
  );
}

export const NotificationProgrammer = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
  
    const unsubscribe = getWorkingProjectsSnapshot(
      (projectData) => {
        if (projectData.length > 0) {
          setProjects(projectData);
          setError(null); // Clear any previous errors
        } else {
          setProjects([]); // Ensure an empty array is set
          setError("No working projects found.");
        }
        setLoading(false);
      },
      () => {
        setError("Failed to load projects.");
        setLoading(false);
      }
    );
  
    return () => unsubscribe(); // Cleanup on unmount
  }, []);
  

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <>
      {projects.map((project) => (
        <StructureNotifProg
          key={project.projectId}
          leaderId={project.leaderId}
          pId={project.projectId}
          time={project.time}
        />
      ))}
    </>
  );
};

export const StructureNotifProg = ({ leaderId, pId, time }) => {
  const [open, setOpen] = useState(false);
  const [leaderData, setLeaderData] = useState(null);
  const [project, setProject] = useState(null);
  const inputRef = useRef(null);
  const [timeR, setTimeR] = useState("");

  useEffect(() => {
    if (time) {
      setTimeR(
        new Date(
          time.seconds * 1000 + time.nanoseconds / 1000000
        ).toLocaleDateString("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        })
      );
    }
  }, [time]);

  useEffect(() => {
    const fetchData = async () => {
      const userData = await getUserById(leaderId);
      console.log("Fetched User Data:", userData); // ✅ Debugging log
      if (userData.exists) setLeaderData(userData);

      const projectData = await getProjectById(pId);
      console.log("Fetched Project Data:", projectData); // ✅ Debugging log
      if (projectData.exists) setProject(projectData);
    };

    if (open) {
      inputRef.current?.focus();
      fetchData();
    }
  }, [open]);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <MDBCol xl={12} lg={6} className="notificationLeaderCol">
      <div className="notification-card">
        <div className="notification-header">
          <span className="notification-name">
            {leaderData
              ? `${leaderData.firstName} ${leaderData.lastName}`
              : "Loading..."}
          </span>
          <span style={{ cursor: "pointer" }}>
            <ScanEyeIcon onClick={handleOpen} />
            {open && (
              <NotificationPub open={open} handleOpen={handleOpen} pId={pId} />
            )}
          </span>
        </div>
        <div className="notification-footer">
          <span className="notification-timing">{timeR}</span>
        </div>
      </div>
    </MDBCol>
  );
};

export function OnlineUser({ id, user }) {
  const navigate = useNavigate();
  return (
    <MDBCol
      xl={12}
      lg={6}
      key={id}
      className="notificationLeaderCol"
      onClick={() => {
        navigate(`/profile/${user.id}`);
      }}
      style={{ cursor: "pointer" }}
    >
      <div className="notificationLeaderContainer">
        <div className="notificationLeaderContent">
          <span className="notificationLeaderHeader">
            <div className="notificationLeaderInfo">
              <div className="notificationLeaderText">
                <p className="fw-bold mb-1">
                  {user.firstName} {user.lastName}
                </p>
                <p className="text-muted mb-0">{user.email}</p>
              </div>
              <div>
                <MDBTooltip tag="a" wrapperProps={{ href: "#" }}>
                  <MDBBtn
                    outline
                    disabled
                    color="light"
                    size="sm"
                    type="button"
                    className="notificationLeaderBtn"
                  >
                    {user.role}
                  </MDBBtn>
                </MDBTooltip>
              </div>
            </div>
          </span>
        </div>
      </div>
    </MDBCol>
  );
}

export const Activities = ({ project }) => {
  return (
    <MDBListGroupItem
      key={project.id}
      className="d-flex justify-content-between align-items-center listGroupItem"
    >
      <div className="d-flex align-items-center">
        <div className="ms-3">
          <p className="fw-bold mb-1">
            {project.leader ? (
              project.leader
            ) : (
              <span className="notYet">Not Yet</span>
            )}
          </p>
          <figcaption className="blockquote-footer mb-0">
            Project:{" "}
            <cite title="Source Title">{`${project.firstName} ${project.lastName}`}</cite>
          </figcaption>
        </div>
      </div>
      <MDBBtn
        color={
          project.work === "still"
            ? "danger"
            : project.work === "on it"
            ? "warning"
            : "success"
        }
        size="sm"
        type="button"
      >
        {project.work}
      </MDBBtn>
    </MDBListGroupItem>
  );
};

export const NotificationPub = ({ open, handleOpen, pId }) => {
  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(() => {
    const fetchProject = async () => {
      const project = await getProjectById(pId);
      setSelectedProject(project);
    };

    if (pId) fetchProject();
  }, [pId]); // Runs only when `pId` changes

  if (!selectedProject) <Loading />;

  return (
    <MDBModal open={open} tabIndex="-1">
      <MDBModalDialog size="lg">
        <MDBModalContent className="notificationsContentModal">
          {selectedProject ? ( // Ensure `selectedProject` is not null before rendering
            <>
              <Project
                clientN={`${selectedProject.firstName} ${selectedProject.lastName}`}
                dateP={selectedProject.publicationDate}
                stat={selectedProject.status}
                leader={selectedProject.leader}
                puid={selectedProject.userId}
                body={selectedProject.body}
                subject={selectedProject.subject}
                id={pId}
                minAmount={selectedProject.minAmount}
                maxAmount={selectedProject.maxAmount}
                projectNotif={true}
                handleOpen={handleOpen}
              />
            </>
          ) : (
            <Loading />
          )}
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export const ApprovedProject = ({ projectId }) => {
  const [projectContent, setProjectContent] = useState(null);
  const [approvedRequest, setApprovedRequest] = useState([]);
  const [programmerNames, setProgrammerNames] = useState({});

  // Function to listen to approved requests in real-time
  const fetchApprovedRequests = () => {
    const requestQuery = query(
      collection(db, "requestworkingprojects"),
      where("projectId", "==", projectId),
      where("approved", "==", true)
    );

    const unsubscribe = onSnapshot(
      requestQuery,
      (querySnapshot) => {
        if (querySnapshot.empty) {
          console.log("No approved requests found for this project.");
          setApprovedRequest([]);
          return;
        }

        const approvedRequests = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setApprovedRequest(approvedRequests);
      },
      (error) => {
        console.error("Error fetching approved requests:", error);
      }
    );

    return unsubscribe;
  };

  useEffect(() => {
    if (projectId) {
      const unsubscribe = fetchApprovedRequests();

      // Cleanup listener on component unmount or projectId change
      return () => unsubscribe();
    }
  }, [projectId]);

  // Fetch project details
  useEffect(() => {
    const fetchProject = async () => {
      if (projectId) {
        const projectData = await getProjectById(projectId); // Make sure getProjectById is properly implemented
        setProjectContent(projectData);
      }
    };

    fetchProject();
  }, [projectId]);

  // Fetch programmer names in real-time
  useEffect(() => {
    const fetchProgrammerNames = async () => {
      const names = {};
      for (const request of approvedRequest) {
        const userProg = await getUserById(request.programmerId); // Make sure getUserById is properly implemented
        names[
          request.programmerId
        ] = `${userProg.firstName} ${userProg.lastName}`;
      }
      setProgrammerNames(names);
    };

    if (approvedRequest.length > 0) {
      fetchProgrammerNames();
    }
  }, [approvedRequest]);

  const handleRevertApproval = async (programmer) => {
    try {
      const projectRef = doc(db, "workingprojects", programmer.workId);
      const projectSnap = await getDoc(projectRef);

      if (!projectSnap.exists()) {
        console.error("Project not found!");
        return;
      }

      const projectData = projectSnap.data();
      const formsArray = projectData.forms || [];
      const updatedForms = formsArray.map((form) =>
        form.type === programmer.type
          ? { ...form, idprogrammer: "", incharge: false }
          : form
      );

      await updateDoc(projectRef, { forms: updatedForms });

      console.log("Approval reverted successfully!");

      const requestQuery = query(
        collection(db, "requestworkingprojects"),
        where("workId", "==", programmer.workId),
        where("programmerId", "==", programmer.programmerId)
      );

      const requestSnap = await getDocs(requestQuery);

      if (!requestSnap.empty) {
        const requestDoc = requestSnap.docs[0];
        const requestRef = doc(db, "requestworkingprojects", requestDoc.id);

        await updateDoc(requestRef, { approved: false });

        deleteWorkingprogrammer(programmer.workId, programmer.programmerId);

        console.log("Request approval removed!");
      } else {
        console.error("Matching request not found in requestworkingprojects!");
      }
    } catch (error) {
      console.error("Error reverting approval:", error);
    }
  };

  if (!projectContent) {
    return <Loading />;
  }

  return (
    <div className="approved-project-card">
      <div className="approved-project-header">
        <span className="approved-project-subject">
          {projectContent.subject}
        </span>
      </div>

      {approvedRequest.map((approvedR) => (
        <MDBBtn
          key={approvedR.programmerId}
          onClick={() => handleRevertApproval(approvedR)}
        >
          {programmerNames[approvedR.programmerId] || <Loading />}
        </MDBBtn>
      ))}

      <Stack>
        <AvatarGroup size="sm">
          <Avatar
            src="https://cdn.myanimelist.net/r/84x124/images/characters/9/131317.webp?s=d4b03c7291407bde303bc0758047f6bd"
            name="Uchiha Sasuke"
          />
          <Avatar
            src="https://cdn.myanimelist.net/r/84x124/images/characters/7/284129.webp?s=a8998bf668767de58b33740886ca571c"
            name="Baki Ani"
          />
          <Avatar
            src="https://cdn.myanimelist.net/r/84x124/images/characters/9/105421.webp?s=269ff1b2bb9abe3ac1bc443d3a76e863"
            name="Uchiha Chan"
          />
          <Avatar name="+3" />
        </AvatarGroup>
      </Stack>
    </div>
  );
};
